<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-01-09 14:50:10
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-01-10 15:26:59
 * @FilePath: /mediatom-web/src/views/aggregate/Config/AutoManagement/AutoOperatorLog/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="auto-log-box">
    <div class="log-item head">
      <div class="time">时间</div>
      <div class="text">运行日志</div>
    </div>
    <div class="log-list">
      <div class="log-item" v-for="(item, index) in logList" :key="index">
        <div class="time">{{ item.hour }}</div>
        <div class="text">{{ item.content }}</div>
      </div>
      <m-empty v-if="!logList.length" imgHeight="100px">
        <template #description> 暂无数据 </template>
      </m-empty>
    </div>
  </div>
</template>

<script>
import { autoOperatorLogList } from '@/api/autoManagement'
export default {
  name: 'AutoOperatorLog',
  data () {
    return {
      logList: []
    }
  },
  props: {
    query: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    query: {
      handler () {
        this.getLogList()
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    async getLogList () {
      const { data = [] } = await autoOperatorLogList({
        ...this.query,
        groupId: 'autoOperator',
        testId: 'default'
      })
      this.logList = data
    }
  }
}
</script>

<style lang="less" scoped>
.auto-log-box {
  background-color: #fff;
  margin-left: 10px;
  border-radius: 10px;
  @innerPadd: 10px;
  @innerHeight: 40px;
  @outerBorderColor: #ddd;
  width: 450px;
  height: 300px;
  position: relative;
  padding: @innerPadd;
  padding-top: calc(@innerPadd + @innerHeight);
  // padding-top: @innerHeight;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .log-list {
    flex: 1;
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid;
    border-color: #fff @outerBorderColor @outerBorderColor @outerBorderColor;
    border-radius: 0 0 5px 5px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }
  .log-item {
    width: 100%;
    height: @innerHeight;
    display: flex;
    box-sizing: border-box;
    box-shadow: 1px 0 1px 0 #ccc;
    &.head {
      width: calc(100% - @innerPadd * 2);
      position: absolute;
      top: @innerPadd;
      // top: 0;
      left: @innerPadd;
      background-color: #eee;
      border-radius: 5px 5px 0 0;
      border-width: 1px 1px 0 1px;
      border-style: solid;
      border-color: @outerBorderColor;
      // border: 1px solid @outerBorderColor;
      .time,
      .text {
        color: #000;
        font-weight: 600;
      }
    }
    .time,
    .text {
      box-sizing: border-box;
      text-align: center;
      line-height: @innerHeight;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      padding: 0 10px;
      color: #7C7C7C;
    }
    .time {
      min-width: 160px;
      border-right: 1px solid @outerBorderColor;
    }
    .text {
      flex-grow: 1;
    }
  }
}
</style>
