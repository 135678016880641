<template>
  <div class="aggregate-table-container">
    <div class="aggregate_table_header"></div>
    <div class="aggregate_table_middle" v-if="query.groupId" style="margin-bottom: 10px">
      <div class="aggregate_table_middle_left">
        <a-dropdown-button
          :trigger="['click']"
          type="primary"
          style="border-radius: 0px !important"
          @click="handleAddSource"
        >
          <a-icon type="plus" />
          广告源
          <a-menu slot="overlay">
            <!-- <a-menu-item key="1" @click="handleAddSource"> <a-icon type="plus" />单个添加 </a-menu-item> -->
            <a-menu-item style="width: 130px" key="2" @click="handleBatchAddSource">
              <a-icon type="plus-square" />批量添加
            </a-menu-item>
            <a-menu-item style="width: 130px" key="3" @click="handleBatchCopy">
              <CopySvg style="margin-right: 8px" />批量复制
            </a-menu-item>
            <a-menu-item style="width: 130px" key="4" @click="handleBatchExport">
              <a-icon type="export" />批量导入
            </a-menu-item>
          </a-menu>
        </a-dropdown-button>
        <a-dropdown :trigger="['click']" :disabled="selectTableItems.length === 0">
          <a-menu slot="overlay">
            <a-menu-item key="1" @click="batchOperation('A')"> <a-icon type="check" />批量开启 </a-menu-item>
            <a-menu-item key="2" @click="batchOperation('S')"> <a-icon type="close" />批量关闭 </a-menu-item>
            <a-menu-item key="3" @click="batchOperation('D')"> <a-icon type="delete" />批量删除 </a-menu-item>
            <a-menu-item key="4" @click="batchCopySource"> <CopySvg style="margin-right: 8px"/>批量复制 </a-menu-item>
          </a-menu>
          <a-button style="margin-left: 8px"> 批量操作 <a-icon type="down" /> </a-button>
        </a-dropdown>
        <span>并行请求数：{{ concurrentNum }}</span>
      </div>
      <div>
        <m-select-more
          style="width: 180px"
          :showSelectedPart="true"
          :allData="platArr"
          v-model="platIds"
          label="广告平台"
          @change="changePlatIds"
        />
        <!-- <a-button
          v-if="openNum === 0 && !isLoading"
          type="primary"
          style="margin: 0 10px; border-radius: 5px"
          @click="addRecommendTemplate"
        >查看推荐瀑布流</a-button
        > -->
        <a-button type="link" @click="handleClickCustTagsBtn"><a-icon type="edit" />自定义指标</a-button>
        <a-button
          v-if="closeNum !== 0 || openNum !== 0"
          type="link"
          @click="downloadReport"
          :loading="downloading"
        ><a-icon type="download" />导出</a-button
        >
      </div>
    </div>
    <div class="table_wrapper">
      <a-table
        :loading="isLoading"
        class="aggregate_table"
        size="middle"
        ref="tableSort"
        :pagination="false"
        rowKey="id"
        :columns="columns"
        :data-source="isExpend ? filterData : dataList"
        :scroll="{ x: true }"
        :rowClassName="rowClassName"
        tableLayout="auto"
        :row-selection="{
          onChange: handleRowChange,
          onSelect: handleRowSelect,
          onSelectAll: handleRowSelectAll,
          hideDefaultSelections: true,
          selectedRowKeys: selectTableItems,
          getCheckboxProps: (record) => {
            return {
              props: {
                disabled: record.id === 'all' || record.id === 'expend' || record.type === 'sdk'
              }
            }
          },
          selections: [
            {
              key: 'all-data',
              text: '全部广告源',
              onSelect: () => {
                this.selectTableItems = this.dataList
                  .filter((item) => item.id !== 'all' && item.id !== 'expend')
                  .map((item) => item.id)
                this.isExpend = false
              }
            },
            {
              key: 'a-data',
              text: '全部已开启广告源',
              onSelect: () => {
                this.selectTableItems = this.dataList.filter((item) => item.sts === 'A').map((item) => item.id)
              }
            },
            {
              key: 's-data',
              text: '全部已关闭广告源',
              onSelect: () => {
                this.selectTableItems = this.dataList.filter((item) => item.sts === 'S').map((item) => item.id)
                this.isExpend = false
              }
            }
          ]
        }"
        :locale="tablenodata"
      >
        <template :slot="item.prop" v-for="item in sureArr" slot-scope="text, record">
          <div :key="item.prop">
            <!-- 百分比数值 -->
            <span v-if="record.type !== 'expend' && isRota(item.prop)" :title="text">{{ text }}%</span>
            <!-- 填充耗时 -->
            <div
              v-else-if="record.type !== 'expend' && item.prop === 'advResponseTime'"
              style="width: 100%; display: flex; justify-content: center"
              :title="text"
            >
              <RowAdvResponseTime :maxAdvResponseTime="+maxAdvResponseTime" :advResponseTime="+text">
              </RowAdvResponseTime>
            </div>
            <span v-else :title="numFormat(text, 3, '')">{{ numFormat(text, 3, '') }}</span>
          </div>
        </template>
      </a-table>
    </div>
    <SourceModal
      v-bind="$attrs"
      :positionId="positionId"
      v-if="addSourceVisible"
      :visible="addSourceVisible"
      :sourceInfo="isAdd ? undefined : sourceInfo"
      @modalCancel="addSourceVisible = false"
      :isAdd="isAdd"
      :query="query"
      @changeSource="getTableData"
    />
    <adxSourceModal
      v-bind="$attrs"
      :positionId="positionId"
      v-if="adxSourceVisible"
      :visible="adxSourceVisible"
      :sourceInfo="isAdd ? undefined : sourceInfo"
      @modalCancel="adxSourceVisible = false"
      :isAdd="isAdd"
      :query="query"
      @changeSource="getTableData"
    />
    <FrequencyModal
      v-if="frequencyVisible"
      :visible="frequencyVisible"
      :sourceInfo="sourceInfo"
      @modalCancel="frequencyVisible = false"
      @changeSource="getTableData"
      :query="query"
    />
    <CustTagsModal
      :targetArr="targetArr"
      :sureArr="sureArr"
      :storageName="storageName"
      :visible="custTagsVisible"
      @modalCancel="custTagsVisible = false"
      @changeCustTags="changeCustTags"
      :defaultCheckedList="defaultCheckedList"
    />
    <BatchCopyModal
      v-if="batchCopyVisible"
      :visible="batchCopyVisible"
      @modalCancel="batchCopyVisible = false"
      :query="query"
      @batchCopySuccess="getTableData"
    />
    <BatchExportModal
      v-bind="$attrs"
      v-if="batchExportVisible"
      :visible="batchExportVisible"
      @modalCancel="batchExportVisible = false"
      :query="query"
      @batchExportSuccess="getTableData"
      :positionId="positionId"
    />
    <BatchCopySource
      v-if="batchCopySourceVisible"
      :visible="batchCopySourceVisible"
      @modalCancel="batchCopySourceVisible = false"
      :query="query"
      :sourceList="selectTableItems"
    />
    <WarningModal v-if="warning" :visible="warning" @modalCancel="warning = false" :parameters="query" />
    <a-modal
      :title="`${title}推荐配置`"
      :visible="showSelectRecommendType"
      @ok="handleSelectRecommendType"
      @cancel="showSelectRecommendType = false"
      width="800px"
    >
      <div class="recommend-type-box">
        <div class="recommend-type-header">
          <p>{{ title }}基于平台自有优化经验，动态为你生成推荐瀑布流，进一步提升变现效率。</p>
        </div>
        <div class="recommend-type-content">
          <div class="recommend-type-item" @click="recommendType = 1" :class="{ active: recommendType === 1 }">
            <div class="recommend-type-item-header">
              <div class="title">精简版</div>
              <span class="desc">广告位数量更少、操作成本更低，适合新手使</span>
            </div>
            <div class="recommend-type-item-img">
              <img :src="recommendImg1" alt="" />
            </div>
          </div>
          <div class="recommend-type-item" @click="recommendType = 0" :class="{ active: recommendType === 0 }">
            <div class="recommend-type-item-header">
              <div class="title">标准版</div>
              <span class="desc">瀑布流配置更复杂，适合有一定经验的开发者</span>
            </div>
            <div class="recommend-type-item-img">
              <img :src="recommendImg2" alt="" />
            </div>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {
  getAggregateTableData,
  updateSource,
  deleteSource,
  batchUpdateSourceSts,
  platformLinkList
} from '@/api/aggregate'
import { getPlatList } from '@/api/common'
import SwithSvg from '@/assets/icons/swith.svg?inline'
import AnctionSvg from '@/assets/icons/action.svg?inline'
import RowPriorityItem from './rowPriorityItem'
import RowPlatItem from './rowPlatItem.vue'
import CustTagsModal from '@/components/common/CustTagsModal'
import custTagObj from './localColumns'
import localDataName from '@/utils/localDataName'
import RowAdvResponseTime from './RowAdvResponseTime.vue'
import { numFormat } from '@/utils/dealNumber'
import CopySvg from '@/assets/icons/copy.svg?inline'
import { mapState } from 'vuex'
import rateAndMoneyMethod from '@/mixins/rateAndMoneyMethod'

// import { aggregateDataArr } from '@/utils/targetArr'
import excel from '@/utils/excel'
import request from '@/utils/request'
// 清除过期的自定义指标缓存
const SourceModal = () => import(/* webpackChunkName: "dialog" */ '../components/sourceModal')
const adxSourceModal = () => import(/* webpackChunkName: "dialog" */ '../components/adxSourceModal')
const FrequencyModal = () => import(/* webpackChunkName: "dialog" */ '../components/frequencyModal')
const BatchCopySource = () => import(/* webpackChunkName: "dialog" */ '../components/batchCopySourceMpdal')
const BatchCopyModal = () => import(/* webpackChunkName: "dialog" */ '../components/batchCopyModal')
const WarningModal = () => import(/* webpackChunkName: "dialog" */ '../components/warningModal')
const BatchExportModal = () => import(/* webpackChunkName: "dialog" */ '../components/batchExportModal')
export default {
  name: 'AggregateTable',
  mixins: [rateAndMoneyMethod],
  components: {
    SwithSvg,
    AnctionSvg,
    adxSourceModal,
    SourceModal,
    RowPriorityItem,
    FrequencyModal,
    // CustTagsModal,
    CustTagsModal,
    BatchCopyModal,
    BatchExportModal,
    RowAdvResponseTime,
    WarningModal,
    BatchCopySource,
    CopySvg
  },
  data () {
    return {
      defaultCheckedList: [],
      // 自定义指标
      targetArr: [], // 指标数组
      storageName: localDataName.aggregateConfig, // 本地缓存key
      sureArr: [], // 最终展示数组
      sureColumns: [],
      columns: [],
      // sureLength: 0,
      custTagsVisible: false,
      scrollLeft: 0, // 可拖拽table的偏移量
      selectedRowKeys: [],
      // 添加广告源
      addSourceVisible: false,
      // meadiatom广告源
      adxSourceVisible: false,
      // 频次控制
      frequencyVisible: false,
      // 批量复制广告源
      batchCopyVisible: false,
      // 批量导入
      batchExportVisible: false,
      batchCopySourceVisible: false,
      showSelectRecommendType: false,
      recommendImg1: require('@/assets/images/recommend1.png'),
      recommendImg2: require('@/assets/images/recommend2.png'),
      recommendType: 1,
      isAdd: true,
      sourceInfo: {},
      dataList: [],
      hasSdk: -1,
      openNum: 0,
      closeNum: 0,
      sdkNum: 0,
      biddingOpenNum: 0,
      isLoading: false,
      platArr: [], // 广告平台下拉
      baseColumns: [
        {
          dataIndex: 'priority',
          title: '优先级',
          align: 'left',
          width: 60,
          fixed: true,
          // 不支持导入
          customRender: (text, record, index) => {
            return {
              children: (
                <RowPriorityItem
                  record={record}
                  index={index}
                  isExpend={this.isExpend}
                  onhandleExpend={this.handleExpend}
                />
              ),
              attrs: {
                colSpan: record.type === 'expend' ? 5 : 1
              }
            }
          }
        },
        {
          title: '广告源',
          dataIndex: 'name',
          align: 'left',
          fixed: true,
          width: '200px',
          customRender: (text, record, index) => {
            return {
              children: <RowPlatItem query={this.query} record={record} index={index} platList={this.platList} />,
              attrs: {
                colSpan: record.type === 'expend' ? 0 : '1'
              }
            }
          }
        },
        {
          dataIndex: '',
          title: '操作',
          align: 'center',
          width: 80,
          fixed: true,
          customRender: (text, record, index) => {
            const obj = {
              children: text,
              attrs: {}
            }
            // 折叠
            if (record.type === 'expend') {
              obj.attrs.colSpan = 0
            }
            if (record.type !== 'expend' && record.type !== 'all' && record.type !== 'sdk') {
              obj.children = (
                <div>
                  <a-icon
                    title="编辑"
                    class="edit_icon icon primary-text"
                    type="form"
                    onclick={() => {
                      this.isAdd = false
                      this.sourceInfo = record
                      this.addSourceVisible = true
                    }}
                    style="font-size:18px;cursor:pointer;margin-right:5px"
                  />
                  <a-popover
                    ref={'settingPopover' + index}
                    overlayClassName="more_setting_popover"
                    trigger="hover"
                    placement="right">
                    <a-icon type="more" class="icon more_icon" style="font-size:18px;cursor: pointer;" />
                    <div slot="content">
                      {record.platId < 900 && record.platId !== 5 ? (
                        <div
                          class="more_setting"
                          onclick={() => {
                            this.query.id = record.id
                            this.warning = true
                            this.$refs['settingPopover' + index].$children[0].sVisible = false
                          }}>
                          <a-icon class="waring_icon icon" type="alert" />
                          异常诊断
                        </div>
                      ) : (
                        ''
                      )}
                      <div
                        class="more_setting"
                        onclick={() => {
                          this.sourceInfo = record
                          this.frequencyVisible = true
                          this.$refs['settingPopover' + index].$children[0].sVisible = false
                        }}>
                        <a-icon type="setting" class="setting_icon icon" />
                        频次控制
                      </div>
                      <div
                        class="more_setting"
                        onclick={async () => {
                          const row = JSON.parse(JSON.stringify(record))
                          record.sts === 'A' ? (row.sts = 'S') : (row.sts = 'A')
                          row.groupId = this.query.groupId
                          row.testId = this.query.testId
                          row.appId = this.query.appId
                          const resp = await updateSource(row)
                          if (resp.code === 200) {
                            this.$message.success('修改成功！')
                            this.getTableData()
                            this.$refs['settingPopover' + index].$children[0].sVisible = false
                          }
                        }}>
                        <SwithSvg
                          style={{ color: record.sts === 'S' ? '#13CE66' : 'orange', width: '13px', height: '13px' }}
                          class="switch_icon icon"
                        />
                        {record.sts === 'A' ? '关闭' : '开启'}
                      </div>
                      <a-popconfirm
                        icon={false}
                        title="确认删除该广告源?"
                        ok-text="是"
                        cancel-text="否"
                        onconfirm={async () => {
                          const resp = await deleteSource({
                            placeId: this.query.placeId,
                            groupId: this.query.groupId,
                            testId: this.query.testId,
                            id: record.id
                          })
                          if (resp.code === 200) {
                            this.$message.success('删除成功！')
                            this.getTableData()
                          }
                        }}>
                        <div class="more_setting">
                          <a-icon class="delete_icon icon" type="delete" />
                          删除
                        </div>
                      </a-popconfirm>
                    </div>
                  </a-popover>
                </div>
              )
            }
            if (record.type === 'sdk') {
              obj.children = (
                <div>
                  <a-switch
                    onChange={async (e) => {
                      record.sts = e ? 'A' : 'S'
                      await this.changeSdkSts(e, record)
                    }}
                    size={'small'}
                    checked={record.sts === 'A'}></a-switch>
                  <AnctionSvg
                    onclick={() => {
                      this.isAdd = false
                      this.sourceInfo = record
                      this.adxSourceVisible = true
                    }}
                    style="font-size:18px;cursor:pointer;margin:0 0 -4px 7px;"></AnctionSvg>
                </div>
              )
            }
            return obj
          }
        },
        {
          title: '价格',
          dataIndex: 'bidfloor',
          align: 'center',
          width: 100,
          fixed: true,
          customRender: (text, record, index) => {
            const obj = {
              children: text,
              attrs: {}
            }
            // 折叠
            if (record.type === 'expend') {
              obj.attrs.colSpan = 0
            }
            // 汇总
            if (record.type === 'all') {
              obj.children = <span>￥{record.placePrice}</span>
            }
            // 竞价（开启）
            if ((record.sts === 'A' && record.settleType) || record.type === 'sdk') {
              obj.children = <span class='primary-text'>竞价</span>
            }
            // 固价（开启）
            if (record.sts === 'A' && (record.settleType === 0 || record.type === 'bottoms')) {
              obj.children = (
                <a-popover
                  overlayClassName="bidfloor_popover"
                  ref={'bidfloor_popover_' + record.id}
                  onvisibleChange={(e) => {
                    if (!e) {
                      record.oldBidfloor = record.bidfloor
                    }
                  }}
                  placement="right"
                  trigger="click">
                  <div style="cursor:pointer">
                    <div class="bidfloor">
                      <span
                        title="开启自动价格"
                        style={{ display: record.isAutoSorting === '1' ? 'inline-block' : 'none' }}
                        class={[record.isAutoSorting === '1' ? 'isopen' : 'isclose', 'auto_price']}></span>
                      ￥{record.bidfloor}
                      <a-icon title="修改价格" class="edit_bidfloor_icon" type="edit" />
                    </div>
                  </div>
                  <div style="width:200px" slot="content">
                    <a-input
                      size="small"
                      type="number"
                      style="width:150px"
                      value={record.oldBidfloor}
                      oninput={(e) => {
                        const num = +e.target.value
                        if (num < 0.01) {
                          record.oldBidfloor = 0.01
                        } else {
                          record.oldBidfloor = +e.target.value
                        }
                      }}
                      onkeydown={(e) => {
                        if (e.key === 'Enter') {
                          this.handleBtnEditBiddfloor(e, record, index)
                        }
                      }}>
                      <span slot="prefix">￥</span>
                    </a-input>
                    <a-button
                      type="link"
                      size="small"
                      onclick={(e) => {
                        this.handleBtnEditBiddfloor(e, record, index)
                      }}>
                      确定
                    </a-button>
                  </div>
                </a-popover>
              )
            }
            // 竞价（关闭）
            if (record.sts === 'S' && record.settleType) {
              obj.children = <span class='primary-text'>竞价</span>
            }
            // 固价（关闭）
            if (record.sts === 'S' && record.settleType === 0) {
              obj.children = <span>￥{record.bidfloor}</span>
            }
            // 兜底（关闭）
            if (record.sts === 'S' && record.type === 'bottoms') {
              obj.children = <span>￥{record.bidfloor}</span>
            }
            return obj
          }
        }
      ],
      platList: [],
      isExpend: true,
      filterData: [],
      timer: null,
      selectTableItems: [],
      downloading: false,
      maxAdvResponseTime: undefined,
      warning: false,
      tablenodata: {
        emptyText: () => (
          <m-empty>
            <div slot="description">暂无广告源！</div>
          </m-empty>
        )
      },
      platIds: []
    }
  },
  props: {
    query: {
      default: () => ({}),
      type: Object
    },
    concurrentNum: {
      default: 0,
      type: [Number, String]
    },
    abtestNum: {
      default: 1,
      type: Number
    },
    positionId: {
      default: 0,
      type: [Number, String]
    }
  },
  watch: {
    query: {
      handler (val, oldval) {
        if (!val.testId && !val.groupId) return
        // if (this.platIds) return
        // if (oldval && val.appId === oldval.appId &&
        // val.placeId === oldval.placeId &&
        // val.testId === oldval.testId &&
        // val.groupId === oldval.groupId &&
        // val.dateStart === oldval.dateStart &&
        // val.dateEnd === oldval.dateEnd) {
        //   return
        // }
        this.query.platIds = this.platIds
        this.getTableData()
      },
      immediate: true,
      deep: true
    }
  },
  created () {
    this.getPlatList()
    // 广告平台下拉
    this.getplatArr()
  },
  mounted () {
    // 添加监听滚动条事件
    window.addEventListener('scroll', this.handleScroll, true)
    this.initTargetArr()
  },
  destroyed () {
    // 移除（跳转页面后移除）
    window.removeEventListener('scroll', this.handleScroll, true)
  },
  updated () {
    this.scrollInit()
  },
  computed: {
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      // 平台名
      title: (state) => state.autoweb.title,
      allRoutes: (state) => state.permission.allRoutes,
      origin: (state) => state.autoweb.origin
    })
  },
  methods: {
    numFormat,
    async changeSdkSts (sts, record) {
      this.isLoading = true
      record.sts = sts ? 'A' : 'S'
      record.appId = this.query.appId
      record.groupId = this.query.groupId
      record.testId = this.query.testId
      const resp = await updateSource(record)
      if (resp.code === 200) {
        this.$message.success('修改成功！')
        this.filterData[0].name = this.openNum + (sts ? 1 : 0) + '个广告源开启'
        this.dataList[0].name = this.openNum + (sts ? 1 : 0) + '个广告源开启'
      }
      this.isLoading = false
    },
    changePlatIds (ids) {
      this.query.platIds = ids
      this.platIds = ids
      this.getTableData()
      this.$emit('changePlatId', this.platIds)
    },
    // 获取全部广告平台列表
    async getplatArr () {
      const resp = await platformLinkList()
      if (resp.code === 200) {
        this.platArr = resp.data || []
      }
    },
    // 导出报表
    async downloadReport () {
      this.downloading = true
      const codeList = []
      const nameList = ['优先级', '广告平台', '广告源', '状态', '价格']
      this.sureArr.forEach((item) => {
        codeList.push(item.prop)
        nameList.push(item.label)
      })
      const query = { ...this.query }
      query.codeList = codeList
      query.nameList = nameList
      try {
        const res = await this.exportToExcel(query)
        await excel(res, `aggregate.xlsx`)
      } catch (e) {
        console.log(e, '导出excel错误信息')
      }
      this.downloading = false
    },
    // 导出表格接口函数
    exportToExcel (query) {
      return request.post('/aggregate/downloadReport', query, {
        responseType: 'blob',
        onUploadProgress: (progress) => {}
      })
    },
    changeCustTags (targetArr, sureArr) {
      this.targetArr = targetArr
      this.sureArr = sureArr
      this.getColumns()
    },
    // 自定义指标
    handleClickCustTagsBtn () {
      this.custTagsVisible = true
    },
    // 获取指标列表数组
    initTargetArr () {
      const tagInfo = localStorage.getItem(this.storageName)
        const { sureArr, targetArr } = custTagObj
        this.defaultCheckedList = sureArr.map((item) => ({ ...item }))
      if (!tagInfo) {
        this.targetArr = JSON.parse(JSON.stringify(targetArr))
        this.sureArr = JSON.parse(JSON.stringify(sureArr))
      } else {
        this.targetArr = JSON.parse(tagInfo).targetArr
        this.sureArr = JSON.parse(tagInfo).sureArr
      }
      this.getColumns()
    },
    // 获取columns
    getColumns () {
      // this.sureLength = 0
      const num = this.sureArr.length
      this.sureColumns = this.sureArr.map((item) => {
        // this.sureLength += item.width
        return {
          title: (
            <div>
              <m-tips showIcon={false} content={item.desc} title={item.label} width={150}></m-tips>
            </div>
          ),
          dataIndex: item.prop,
          width: num <= 7 ? null : item.width,
          align: 'center',
          scopedSlots: { customRender: item.prop }
        }
      })
      this.columns = [...this.baseColumns, ...this.sureColumns]
    },

    rowClassName (record, index) {
      if (index === 0) {
        return 'all_col'
      } else if (index === 1 && this.hasSdk !== -1) {
        return 'sdk_col'
      } else if (index === this.openNum + 2) {
        return 'expend_col'
      }
      // 正常广告源起始位置（开启）
      // 汇总数 + 竞价开启数 + sdk数
      const sdkNum = this.hasSdk !== -1 ? 1 : 0
      const startIndex = this.biddingOpenNum + 1 + sdkNum
      // 正常广告源结束位置（开启）
      // 开启数（竞价开启 和 固价开启） + sdk数 + 汇总数 - 1
      const endIndex = this.openNum + sdkNum
      if (index >= startIndex && index <= endIndex) {
        // 瀑布流优先级
        const i = index - startIndex + 1
        if (Math.ceil(i / this.concurrentNum) % 2 === 0) {
          // group1
          return 'group1-row'
        } else if (Math.ceil(i / this.concurrentNum) % 2 === 1) {
          // group2
          return 'group2-row'
        }
      }
    },
    handleBatchAddSource () {
      this.$router.push({
        name: 'batchAddSource',
        query: {
          appId: this.query.appId,
          placeId: this.query.placeId,
          groupId: this.query.groupId,
          fromPage: 'config',
          testId: this.query.testId,
          positionId: this.positionId
        }
      })
    },
    handleSelectRecommendType () {
      this.$router.push({
        name: 'recommendTemplate',
        query: {
          appId: this.query.appId,
          placeId: this.query.placeId,
          groupId: this.query.groupId,
          testId: this.query.testId,
          positionId: this.positionId,
          type: this.recommendType
        }
      })
    },
    addRecommendTemplate () {
      this.showSelectRecommendType = true
    },
    handleBatchCopy () {
      this.batchCopyVisible = true
    },
    handleBatchExport () {
      this.batchExportVisible = true
    },
    batchCopySource () {
      console.log(123123)
      this.batchCopySourceVisible = true
    },
    async batchOperation (status) {
      const _this = this
      this.$confirm({
        title: '提示',
        content: (h) => (
          <div>
            确定{status === 'A' ? '开启' : status === 'S' ? '关闭' : status === 'D' ? '删除' : ''}所选中的广告源嘛？
          </div>
        ),
        async onOk () {
          const batchOperationQuery = {
            sts: status,
            groupId: _this.query.groupId,
            placeId: _this.query.placeId,
            testId: _this.query.testId,
            ids: _this.selectTableItems
          }
          const resp = await batchUpdateSourceSts(batchOperationQuery)
          if (resp.code === 200) {
            _this.$message.success(`操作成功！`)
            _this.getTableData()
          }
        },
        onCancel () {}
      })
    },
    handleAddSource () {
      this.isAdd = true
      this.addSourceVisible = true
    },
    handleRowChange (selectedRowKeys, selectedRows) {
      this.selectTableItems = selectedRowKeys
    },
    handleRowSelect (record, selected, selectedRows) {},
    handleRowSelectAll (selected, selectedRows, changeRows) {},
    handleBtnEditBiddfloor (e, row, index) {
      row.groupId = this.query.groupId
      row.testId = this.query.testId
      row.appId = this.query.appId
      row.bidfloor = row.oldBidfloor
      updateSource(row).then((res) => {
        if (res.code === 200) {
          this.$message.success('价格修改成功！')
          this.$refs['bidfloor_popover_' + row.id].$children[0].sVisible = false
          this.getTableData()
        }
      })
    },
    // 吸顶
    handleScroll (e) {
      // 防止事件冒泡，只监听document上的滚动事件
      if (!e.bubbles) return
      const scrollTop = document.documentElement.scrollTop // 滑动的距离
      if (!this.$refs['tableSort']) {
        return
      }
      // table-header吸顶
      // table_wrapper
      const heightTop = this.$refs['tableSort'].$parent.$el.offsetTop // 距离顶部高度
      // fixed表头
      const fixedThead = document.getElementsByClassName('ant-table-thead')[1].getElementsByTagName('tr')[0]
      // 右侧表头
      const rightThead = document.getElementsByClassName('ant-table-thead')[0].getElementsByTagName('tr')[0]
      if (scrollTop >= 145) {
        fixedThead.style.opacity = 0
        rightThead.style.opacity = 0
        // 表头到达页面顶部固定表头
        const top = scrollTop - heightTop
        // ant-table-thead
        fixedThead.style.position = 'sticky'
        fixedThead.style.zIndex = '100'
        fixedThead.style.top = `${top - 135}px`
        rightThead.style.position = 'sticky'
        rightThead.style.zIndex = '1'
        rightThead.style.top = `${top - 135}px`
      } else {
        fixedThead.style.position = ''
        fixedThead.style.zIndex = ''
        fixedThead.style.top = ``
        rightThead.style.position = ''
        rightThead.style.zIndex = ''
        rightThead.style.top = ``
      }
      // 侧边栏吸顶
      // const leftHeightTop = document.getElementsByClassName('main_left_main')[0].offsetTop // 距离顶部高度
      if (scrollTop >= 100 && document.getElementsByClassName('main_left_main')[0].style.position !== `fixed`) {
        document.getElementsByClassName('main_left_main')[0].style.top = `120px`
        document.getElementsByClassName('main_left_main')[0].style.position = `fixed`
        // document.getElementsByClassName('main_left_main')[0].style.width = `250px`
      } else if (
        scrollTop < 100 &&
        document.getElementsByClassName('main_left_main')[0].style.position !== `absolute`
      ) {
        document.getElementsByClassName('main_left_main')[0].style.top = ``
        document.getElementsByClassName('main_left_main')[0].style.position = `absolute`
      }
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        fixedThead.style.opacity = 1
        rightThead.style.opacity = 1
      }, 300)
    },
    scrollInit () {
      const wrapper = document.getElementsByClassName('ant-table-scroll')[0]
      if (!wrapper) {
        return
      }
      // 获取要绑定事件的元素
      const table = wrapper.getElementsByClassName('ant-table-body')[0]
      if (!table) {
        return
      }
      var flag // 鼠标按下
      var downX // 鼠标点击的x下标
      var scrollLeft // 当前元素滚动条的偏移量
      table.addEventListener('mousedown', function (event) {
        flag = true
        downX = event.clientX // 获取到点击的x下标
        scrollLeft = this.scrollLeft // 获取当前元素滚动条的偏移量
      })
      table.addEventListener('mousemove', function (event) {
        if (flag) {
          // 判断是否是鼠标按下滚动元素区域
          var moveX = event.clientX // 获取移动的x轴
          var scrollX = moveX - downX // 当前移动的x轴下标减去刚点击下去的x轴下标得到鼠标滑动距离
          this.scrollLeft = scrollLeft - scrollX // 鼠标按下的滚动条偏移量减去当前鼠标的滑动距离
          table.scrollTo(this.scrollLeft, 0)
        }
      })
      // 鼠标抬起停止拖动
      table.addEventListener('mouseup', function () {
        flag = false
      })
      // 鼠标离开元素停止拖动
      table.addEventListener('mouseleave', function (event) {
        flag = false
      })
    },
    // 折叠按钮
    handleExpend () {
      this.isExpend = !this.isExpend
    },
    getPlatName (id) {
      return this.platList.filter((item) => item.id === id)[0].name
    },
    // 获取table数据
    async getPlatList () {
      const resp = await getPlatList()
      this.platList = resp.data || []
    },
    async getTableData () {
      this.selectTableItems = []
      this.isLoading = true
      const resp = await getAggregateTableData(this.query)
      this.maxAdvResponseTime = resp.data && resp.data.maxAdvResponseTime
      // this.isExpend = true
      this.dataList = []
      // 汇总数据
      const sum = resp.data.reportData || []
      sum.advResponse = sum.response
      // 正常广告源
      let sources = resp.data.sources || []
      sources = sources.map((item) => ({ ...item.reportData, ...item }))
      // 保存原价  type sources 正常
      sources.forEach((item) => {
        this.$set(item, 'type', 'sources')
        item.oldBidfloor = item.bidfloor
      })
      // 预算
      this.hasSdk = sources.findIndex((item) => +item.platId === 0)
      let sdkArr = []
      if (this.hasSdk !== -1) {
        sdkArr = sources.splice(0, 1)
        this.$set(sdkArr[0], 'type', 'sdk')
        this.sdkNum = 1
      } else {
        this.sdkNum = 0
      }
      // 所有的开启正常广告源
      const allSourcesOpenDatas = sources.filter((item) => item.sts === 'A')
      // 开启的 竞价 正常广告源
      const biddingSourcesOpenDatas = allSourcesOpenDatas.filter((item) => item.settleType) // settleType 1,2,3
      // 开启的 固价 正常广告源
      const fixedSourcesOpenDatas = allSourcesOpenDatas.filter((item) => item.settleType === 0)
      // 固价优先级
      fixedSourcesOpenDatas.forEach((item, i) => {
        item.priority = i + 1
      })
      this.biddingOpenNum = biddingSourcesOpenDatas.length

      // 所有的关闭的正常广告源
      const allSourcesCloseDatas = sources.filter((item) => item.sts === 'S')
      // 关闭的 竞价 正常广告源
      const biddingSourcesCloseDatas = allSourcesCloseDatas.filter((item) => item.settleType) // settleType 1,2,3
      // 关固价（关闭）闭的 固价 正常广告源
      const fixedSourcesCloseDatas = allSourcesCloseDatas.filter((item) => item.settleType === 0)
      //

      // 兜底广告源
      let bottoms = resp.data.bottomSourceList || []
      bottoms = bottoms.map((item) => ({ ...item.reportData, ...item }))
      // 保存原价  type bottoms 兜底
      bottoms.forEach((item) => {
        this.$set(item, 'type', 'bottoms')
        item.oldBidfloor = item.bidfloor
      })
      // 所有的开启的兜底广告源
      const allBottomsOpenDatas = bottoms.filter((item) => item.sts === 'A')
      // 所有的关闭的兜底广告源
      const allBottomsCloseDatas = bottoms.filter((item) => item.sts === 'S')

      // 开启的 广告源数量（不包括预算广告源）
      this.openNum = biddingSourcesOpenDatas.length + fixedSourcesOpenDatas.length + allBottomsOpenDatas.length
      // 关闭的 广告源数量（不包括预算广告源）固价（关闭）
      this.closeNum = biddingSourcesCloseDatas.length + fixedSourcesCloseDatas.length + allBottomsCloseDatas.length
      //

      // 数据push
      sum.type = 'all'
      sum.id = 'all'
      sum.name =
        this.openNum + (this.hasSdk !== -1 ? sdkArr.filter((item) => item.sts === 'A').length : 0) + '个广告源开启'
      // 流量填充率
      sum.advRequestFilledRate = sum.requestFilledRate
      // 流量请求
      sum.advRequest = sum.request
      // push 汇总
      this.dataList = [sum, ...sdkArr, ...biddingSourcesOpenDatas, ...fixedSourcesOpenDatas, ...allBottomsOpenDatas]
      // 有未开启的广告源
      const expandRow = {
        text: '未启用（' + this.closeNum + '个广告源)',
        type: 'expend',
        id: 'expend'
      }
      if (this.closeNum > 0) {
        this.dataList = [
          ...this.dataList,
          expandRow,
          // 竞价（关闭）
          ...biddingSourcesCloseDatas,
          // 固价（关闭）
          ...fixedSourcesCloseDatas,
          // 兜底（关闭）
          ...allBottomsCloseDatas
        ]
      } else {
        // 有未开启的广告源
      }
      // 开启的+关闭的广告源数量 （不包括预算广告源）
      if (this.closeNum === 0 && this.openNum === 0) {
        // 预算广告源
        this.dataList = [sum, ...sdkArr]
      }
      // 展示总数据
      this.filterData = this.dataList.slice(0, this.openNum + 2 + sdkArr.length)
      // this.filterData.forEach((item) => {
      //   item.request = numFormat(item.request, 3, '')
      //   item.impress = numFormat(item.impress, 3, '')
      //   item.income = numFormat(item.income, 3, '')
      //   item.ecpm = numFormat(item.ecpm, 3, '')
      //   item.advRequest = numFormat(item.advRequest, 3, '')
      //   item.advResponse = numFormat(item.advResponse, 3, '')
      //   item.click = numFormat(item.click, 3, '')
      //   item.askPriceRequest = numFormat(item.askPriceRequest, 3, '')
      //   item.askPriceResponse = numFormat(item.askPriceResponse, 3, '')
      //   item.response = numFormat(item.response, 3, '')
      //   item.askPriceResponse = numFormat(item.askPriceResponse, 3, '')
      //   item.estimatedRevenueEcpm = numFormat(item.estimatedRevenueEcpm, 3, '')
      //   item.unitRequest = numFormat(item.unitRequest, 3, '')
      //   item.unitImpression = numFormat(item.unitImpression, 3, '')
      //   item.unitClick = numFormat(item.unitClick, 3, '')
      // })
      this.filterDataTemp = JSON.parse(JSON.stringify(this.filterData))
      this.isLoading = false
    }
  }
}
</script>
<style lang="less">
.more_setting_popover .ant-popover-inner-content {
  padding: 0;
}

.bidfloor_popover {
  .ant-popover-content {
    .ant-popover-inner-content {
      padding: 5px 10px;
    }
  }
}

.recommend-type-box {
  position: relative;

  .recommend-type-header {
    padding: 10px 20px;
    font-size: 14px;
  }

  .recommend-type-content {
    display: flex;

    .recommend-type-item {
      margin: 20px;
      flex-grow: 1;
      border: 1px solid #ccc;
      height: 300px;
      background: #fff;
      border-radius: 10px;
      overflow: hidden;
      position: relative;
      display: flex;
      padding: 20px;
      flex-direction: column;
      transition: all 0.3s;

      .recommend-type-item-header {
        color: @primary-color;

        .title {
          font-size: 16px;
          font-weight: 600;
        }

        .desc {
          font-size: 14px;
        }
      }

      .recommend-type-item-img {
        flex-grow: 1;
        padding: 30px 20px 20px 20px;
        position: relative;
        overflow: hidden;

        img {
          height: 100%;
          width: 100%;
        }
      }

      &.active {
        border-color: @primary-color;
        background: #f3f6ff;
      }
    }
  }
}
</style>

<style lang="less" scoped>
@import '~@/assets/less/mixins/redefineAnt.less';

.aggregate-table-container {
  width: 100%;

  .aggregate_table_middle {
    display: flex;
    margin-bottom: 20px;
    justify-content: space-between;
    align-items: center;

    .aggregate_table_middle_left {
      .ant-dropdown-button {
        margin-right: 20px;
      }

      span:last-child {
        margin-left: 20px;
      }
    }
  }

  .table_wrapper {
    width: 100%;
  }
}

::v-deep.aggregate-table-container {
  // .ant-table-scroll .ant-table-fixed{
  //   margin-left: 20px;
  // }
  .ant-btn.ant-btn-primary {
    border-radius: 5px 0 0 5px;
    // background: @primary-color;
    height: 36px;
  }

  .ant-table-fixed-left {
    box-shadow: 6px 0 6px -4px rgba(0, 0, 0, 0.15);
  }

  .ant-btn.ant-btn-primary.ant-dropdown-trigger {
    border-radius: 0 5px 5px 0;
    height: 36px;
  }

  .ant-table-wrapper table {
    font-size: 12px;
    // 处理fixed后的错位问题
    .ant-table-tbody > tr > td {
      white-space: nowrap;
      height: 56px !important;
    }
    // 设置表头样式
    thead.ant-table-thead {
      tr th {
        // background: #f1f1f1;
        font-weight: 600;
        font-size: 13px;
      }
      // 防止吸顶后非固定列覆盖固定列
      .ant-table-align-center.ant-table-row-cell-break-word {
        z-index: -1;
      }
    }
  }
  .ant-table.ant-table-layout-fixed {
    table {
      table-layout: none;
    }
  }
}
::v-deep.aggregate-table-container .more_setting_popover {
  border: 1px solid;
}
::v-deep.aggregate-table-container {
  .ant-table-placeholder {
    border-bottom: none;
  }
}
.more_setting {
  height: 24px;
  line-height: 24px;
  cursor: pointer;
  padding: 0 5px;

  &:hover {
    background: fade(@primary-color, 5%);
  }

  .icon {
    margin-right: 14px;

    &.setting_icon {
      color: @primary-color;
    }

    &.delete_icon {
      color: red;
    }

    &.waring_icon {
      color: orange;
    }
  }
}

.bidfloor {
  cursor: pointer;

  .auto_price {
    display: inline-block;
    margin: 3px 3px 0 0;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    border: 3px solid #e7e8ec;

    &.isopen {
      background-color: @primary-color;
    }

    &.isclose {
      background-color: #bbbdc3;
    }
  }

  .edit_bidfloor_icon {
    display: none;
    color: @primary-color;
    font-size: 14px;
    margin-left: 3px;
  }

  &:hover .edit_bidfloor_icon {
    display: inline-block;
  }
}

::v-deep.aggregate-table-container {
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    border-bottom: none;
  }
}
</style>

<style lang="less">
// 瀑布流及选择按钮隐藏
.aggregate_table {
  overflow: hidden;
  position: relative;
  width: 100%;
  .ant-table-selection-column.ant-table-selection-column-custom .ant-checkbox.ant-checkbox-disabled {
    display: none;
  }

  .group1-row {
    background-color: rgb(255, 255, 255);
  }

  .group2-row {
    background-color: rgb(245, 252, 252);
  }

  .ant-table-scroll {
    user-select: none;
    cursor: move;
  }
}
</style>
