<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-02-28 10:50:50
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-02-29 16:30:33
 * @FilePath: /mediatom-web/src/components/sourceModal/OpenScreenSourceForm/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <!-- 跳过是否显示倒计时 -->
    <a-form-model-item
      v-if="
        +positionId === 1 &&
          (+platInfo.accessType === 1 ||
          +platInfo.id === 1019 ||
          (+platInfo.accessType === 0 && +formquery.isAutoDrawing))
      "
      prop="displayCountdown"
      label="跳过是否显示倒计时"
    >
      <a-radio-group name="radioGroup" v-model="formquery.displayCountdown">
        <a-radio :value="1"> 是 </a-radio>
        <a-radio :value="0"> 否 </a-radio>
      </a-radio-group>
    </a-form-model-item>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      formqueryTemp: {
        displayCountdown: 1
      }
    }
  },
  computed: {
    formquery: {
      get () {
        return this.query
      },
      set (val) {
        const formquery = {
          ...this.formqueryTemp,
          ...val
        }
        this.$emit('changeQuery', formquery)
      }
    },
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      title: (state) => state.autoweb.title,
      adminUserId: (state) => state.user.adminUserId,
      currentLoginId: (state) => state.user.id
    }),
    isAdmin () {
      if (this.role === 'SUPER_ADMIN' || +this.adminUserId === 1) {
        return true
      }
      return false
    }
  },
  props: {
    query: {
      default: () => ({
        displayCountdown: undefined
      }),
      type: Object
    },
    positionId: {
      default: 0,
      type: Number
    },
    platInfo: {
      default: () => ({}),
      type: Object
    }
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
</style>
