<template>
  <div class="auto-config-container">
    <div class="header">
      <div class="nav">
        <div class="title">AI自动运营数据</div>
        <a-alert v-if="sts === 'S'" style="margin: -3px 0 0 10px" message="️温馨提示：暂未开启自动运营" banner />
      </div>
      <div class="control">
        <a-button type="link" @click="isExpend = !isExpend"><a-icon class="icon" :type="isExpend ? 'up-circle' : 'down-circle'" />{{ isExpend ? '收起' : '展开' }}</a-button>
        <a-button type="link" @click="editSource">新增广告源</a-button>
      </div>
    </div>
    <div class="middle-box" v-if="isExpend">
      <AutoChart
        v-if="isExpend"
        :chartResp="chartResp"
        :query="baseQuery"
        :appList="appList"
        :placeList="listPlace"
        ref="chart"
      />
      <AutoOperatorLog :query="baseQuery"/>
    </div>
    <AutoTable :query="baseQuery" :appList="appList" :placeList="listPlace" :positionId="positionId" ref="table" />
  </div>
</template>

<script>
import AutoChart from './AutoChart'
import { getPlaceListByAppId } from '@/api/common'
import AutoTable from './AutoTable'
import { getAutoChart } from '@/api/autoManagement'
import AutoOperatorLog from './AutoOperatorLog'
export default {
  name: 'AutoManagement',
  components: { AutoChart, AutoTable, AutoOperatorLog },
  props: {
    baseQuery: {
      default: () => ({}),
      type: Object
    },
    appList: {
      default: () => [],
      type: Array
    },
    placeList: {
      default: () => [],
      type: Array
    }
  },
  computed: {
    positionId () {
      const place = this.placeList.find((item) => item.id === this.baseQuery.placeId)
      return place ? place.position : 0
    }
  },
  watch: {
    baseQuery: {
      handler (val, oldval) {
        this.listPlace = this.placeList
        this.getOperatorType()
        this.getChartData()
      },
      immediate: true,
      deep: true
    }
  },
  data () {
    return {
      operatorType: '1',
      isExpend: false,
      listPlace: [],
      sts: 'A',
      chartResp: {}
    }
  },
  methods: {
    // 获取数据
    async getChartData () {
      const xquery = {
        ...this.baseQuery,
        groupId: 'autoOperator',
        testId: 'default'
      }
      const resp = await getAutoChart(xquery)
      const { data = {} } = resp
      const { values = [] } = data
      this.isExpend = values.some((item) => item > 0)
      this.chartResp = resp || {}
    },
    // 获取运营方式
    async getOperatorType () {
      this.operatorType = this.listPlace.find((item) => item.id === this.baseQuery.placeId)?.operatorType
      // 手动的话  显示开启自动运用 自动的话  应该显示关闭自动运营?
      this.sts = this.operatorType === '1' ? 'A' : 'S'
    },
    // 回显后获取广告位列表
    async getPlaceList () {
      const { data = [] } = await getPlaceListByAppId(this.baseQuery.appId)
      this.listPlace = data
      this.getOperatorType()
      this.sts === 'A' && this.getSource()
    },
    // 打开编辑广告源
    editSource () {
      // this.editSourceVisible = true
      this.$emit('editSource')
      this.editSourceVisible = true
    },
    // 回显后刷新接口
    getSource () {
      this.$nextTick(() => {
        this.$refs.table.getTableData()
        this.$refs.chart.getChartData()
      })
    }
  }
}
</script>

<style lang="less" scoped>
.auto-config-container {
  border-radius: 5px;
  .header {
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    margin: 10px 0 0 0;
    .nav {
      display: flex;
      justify-content: space-between;
    }
    .title {
      line-height: 30px;
      font-size: 13px;
      font-weight: 600;
    }
  }
  ::v-deep .ant-alert-message {
    color: #eda12e !important;
  }
  .control {
    display: flex;
    justify-content: flex-start;
  }
}
.middle-box{
  display: flex;
  flex-wrap: nowrap;
  border-radius: 10px;
  box-sizing: border-box;
  margin: 10px 0 10px 0;
}
</style>
