<template>
  <div class="manual-operation-container">
    <div class="config_main">
      <div class="main_left_main" :style="{ width: isCollapse ? closeLeftWidth + 'px' : openLeftWidrh + 'px' }">
        <div class="main_left_header" :style="{ height: isCollapse ? '30px' : '60px' }">
          <div class="title">流量分组（{{ groupList.length }}）</div>
          <div
            class="add_group_btn"
            v-if="groupInfo && groupInfo.abTests && groupInfo.abTests.length > 0"
            :style="{ width: isCollapse ? '0px' : '120px' }"
          >
            <a-button type="primary" icon="plus" @click="handleClickAddGroup">流量分组</a-button>
          </div>
        </div>
        <div class="main_left_group_list">
          <a-menu
            v-if="groupList.length"
            class="menu_box"
            :key="menuKey"
            :selectedKeys="currentGroup"
            type="inner"
            @select="handleSelectGroup"
          >
            <a-menu-item v-for="item in groupList" :key="item.id">
              <div class="group_list_item" :title="item.name">
                <div class="group_name">
                  <div v-show="!isCollapse" class="request-chart" :title="'流量占比：' + getRate(item).toFixed(2) + '%'">
                    <RequestPieTip :rate="getRate(item)"/>
                  </div>
                  <span v-show="!isCollapse">{{ item.priority }}</span> {{ item.name
                  }}<span v-if="item.abTests.length > 1" class="ab_dot">AB</span>
                </div>
                <div :style="{ width: isCollapse ? '0' : '80px' }" class="group_operation">
                  <img class="icon" @click.stop="addTestModal(item)" v-if="item.abTests.length === 1" src="/images/aggregate/ab.png" alt="">
                  <a-icon
                    title="编辑"
                    @click.stop="handleClickUpdateGroup(item)"
                    class="edit_icon icon primary-text"
                    type="edit"
                  />
                  <a-popconfirm
                    v-if="item.id !== 'default'"
                    :icon="false"
                    title="确认删除该流量分组?"
                    ok-text="是"
                    cancel-text="否"
                    @confirm="handleDelectGroup(item)"
                  >
                    <a-icon title="删除" @click.stop="() => {}" class="delete_icon icon" type="delete" />
                  </a-popconfirm>
                </div>
              </div>
            </a-menu-item>
          </a-menu>
          <m-empty style="max-height: 450px" v-else imgHeight="100px">
            <template #description> 暂无流量分组 </template>
          </m-empty>
        </div>
        <div
          class="main_left_footer"
          @click.stop="
            () => {
              isCollapse = !isCollapse
            }
          "
        >
          <a-icon :type="!isCollapse ? 'double-left' : 'double-right'" />
        </div>
      </div>
      <div class="main_right" :style="{ 'margin-left': isCollapse ? closeLeftWidth + 10 +'px' : openLeftWidrh + 10 + 'px' }">
        <AggregateDetail
          v-if="groupInfo && groupInfo.abTests && groupInfo.abTests.length > 0"
          :groupInfo="groupInfo"
          :searchQuery="query"
          :positionId="currentPositionId"
          :placeName="placeName"
          :appName="appName"
          :groupName="groupName"
          @changeTestInfo="changeTestInfo"
          @handleEndAbtest="changeTestInfo"
          @changePlat="changePlat"
        />
        <m-empty v-else>
          <template #description> 暂无数据 </template>
        </m-empty>
      </div>
    </div>
    <AbtestModal
      :appName="appName"
      :placeName="placeName"
      v-if="addAbtestVisible"
      :visible="addAbtestVisible"
      @modalCancel="addAbtestVisible = false"
      isAdd
      :query="adTestQuery"
      :groupName="testGroupName"
      @changeTestInfo="changeTestInfo"
    />
    <GroupModal
      :groupInfo="updateGroupInfo"
      v-if="addGroupVisible"
      :placeId="query.placeId"
      :visible="addGroupVisible"
      @modalCancel="addGroupVisible = false"
      :areaList="areaList"
      :isAdd="isAddGroup"
      :brandList="brandList"
      @changeGroupInfo="getGroupList"
      :groupList="groupList"
      :countryList="countryList"
    />
  </div>
</template>

<script>
import AggregateDetail from './aggregateDetail'
import { getAreaList, getBrandList, getCountryList } from '@/api/common'
import { getGroupIdListByPlaceForAggregate, changePriority, deleteGroupById } from '@/api/aggregate'
import Sortable from 'sortablejs'
import mixDate from '@/mixins/initDate'
import RequestPieTip from './components/RequestPieTip'
const GroupModal = () => import(/* webpackChunkName: "dialog" */'./components/groupModal')
const AbtestModal = () => import(/* webpackChunkName: "dialog" */'./components/abtestModal')
export default {
  name: 'ManualOperationPage',
  components: { AggregateDetail, AbtestModal, GroupModal, RequestPieTip },
  mixins: [mixDate],
  data () {
    return {
      openLeftWidrh: 300,
      closeLeftWidth: 120,
      updateGroupInfo: {},
      isAddGroup: false,
      addAbtestVisible: false,
      addGroupVisible: false,
      query: {
        dateEnd: '',
        dateStart: '',
        appId: '',
        placeId: '',
        groupId: '',
        testId: ''
      },
      // 当前流量分组
      currentGroup: [],
      groupList: [],
      menuKey: '',
      areaList: [],
      brandList: [],
      isCollapse: false,
      platIds: [],
      adTestQuery: {},
      testGroupName: '',
      totalRequest: 1,
      countryList: []
    }
  },
  computed: {
    // 当前流量分组信息
    groupInfo () {
      return this.groupList.filter((item) => item.id === this.query.groupId)[0] || {}
    },
    appName () {
      const item = this.appList.find((item) => item.id === this.query.appId) || {}
      return item.name
    },
    placeName () {
      const item = this.placeList.find((item) => item.id === this.query.placeId) || {}
      return item.name
    },
    groupName () {
      return this.groupInfo.name || ''
    },
    currentPositionId () {
      const place = this.placeList.find((item) => item.id === this.query.placeId)
      return place ? place.position : 0
    },
    // 是否是联盟广告
    isAlliance () {
      const appInfo = this.appList.find((item) => item.id === this.query.appId) || {}
      return !!(appInfo && appInfo.accessType === 2)
    }
  },
  async mounted () {
    this.getCountryList()
    this.getAreaList()
    this.getBrandList()
  },
  props: {
    baseQuery: {
      default: () => ({}),
      type: Object
    },
    appList: {
      default: () => [],
      type: Array
    },
    placeList: {
      default: () => [],
      type: Array
    }
  },
  watch: {
    baseQuery: {
      async handler (val) {
        // 只有时间发生改变，应用和广告位没变，则不需要获取流量分组列表
        if ((val.dateStart !== this.query.dateStart || val.dateEnd !== this.query.dateEnd) && val.appId === this.query.appId && val.placeId === this.query.placeId) {
          this.query.dateEnd = val.dateEnd
          this.query.dateStart = val.dateStart
          await this.getGroupList()
        } else {
          this.query.groupId = ''
          this.query.testId = ''
          // 否则，不管什么情况都要获取流量分组列表
          this.query.appId = val.appId
          this.query.placeId = val.placeId
          this.query.dateEnd = val.dateEnd
          this.query.dateStart = val.dateStart
          // 重新获取流量分组列表
          await this.getGroupList()
          // 若流量分组中有值，默认为第一项
          this.groupList.length && (this.query.groupId = this.groupList[0].id)
        }
        this.currentGroup = [this.query.groupId]
        // 需要将广告平台筛选项置空
        this.query.platIds = []
        this.$nextTick(() => {
          this.initSort()
        })
      },
      deep: true,
      immediate: true
    }
  },
  provide () {
    return {
      isAlliance: () => {
        return this.isAlliance
      },
      groupList: () => {
        return this.groupList
      }
    }
  },
  methods: {
    async getCountryList () {
      const { data = [] } = await getCountryList()
      this.countryList = data.map((item) => ({ name: item.nameCn, id: item.code }))
    },
    // 流量占比
    getRate (item) {
      const { request = 0 } = item
      return request / (this.totalRequest || 1) * 100
    },
    addTestModal (item) {
      this.testGroupName = item.name
      this.adTestQuery = {
        ...this.query,
        groupId: item.id
      }
      this.addAbtestVisible = true
    },
    changeTestInfo () {
      this.getGroupList()
      setTimeout(() => {
        this.initSort()
      }, 1000)
    },
    handleClickAddGroup () {
      this.isAddGroup = true
      this.addGroupVisible = true
    },
    handleClickUpdateGroup (groupInfo) {
      this.updateGroupInfo = groupInfo
      this.isAddGroup = false
      this.addGroupVisible = true
    },
    async getBrandList () {
      const resp = await getBrandList()
      resp.data &&
        resp.data.forEach((item) => {
          item.id = item.name
        })
      this.brandList = Object.freeze(resp.data || [])
    },
    async getAreaList () {
      const resp = await getAreaList()
      this.areaList = resp.data || []
    },
    // 排序初始化
    initSort () {
      const el = document.querySelector('.menu_box')
      if (!el) return
      /* eslint-disable no-new */
      new Sortable(el, {
        animation: 150,
        onEnd: (evt) => {
          this.handelPriority(evt.newIndex, evt.oldIndex)
        }
      })
    },
    // 拖拽完成的排序事件
    async handelPriority (newIndex, oldIndex) {
      // 判断是否发生顺序变化
      if (newIndex === oldIndex) return
      // 不允许对默认分组的顺序进行修改
      if (newIndex === this.groupList.length - 1 || oldIndex === this.groupList.length - 1) {
        this.$message.error('默认分组优先级不可修改!')
        // 恢复原有顺序
        this.groupList = this.groupListTemp
        // 重新渲染列表
        this.menuKey = Math.random()
        // 渲染完成后初始化排序功能
        setTimeout(() => {
          this.initSort()
        }, 1000)
        return
      }
      const allPrioritys = this.groupList.map((item) => ({ id: item.id }))
      const dragItem = allPrioritys.splice(oldIndex, 1)
      allPrioritys.splice(newIndex, 0, dragItem[0])
      allPrioritys.splice(this.groupList.length - 1, 1)
      const otherPrioritys = allPrioritys.map((item, i) => ({ id: item.id, priority: this.groupList.length - i - 1 }))
      const query = {
        placeId: this.query.placeId,
        segmentPriorityList: otherPrioritys
      }
      changePriority(query).then((res) => {
        if (res.code === 200) {
          this.getGroupList().then((res) => {
            this.menuKey = Math.random()
          })
          setTimeout(() => {
            this.initSort()
          }, 1000)
        }
      })
    },
    changePlat (data) {
      this.query.platIds = data
    },
    // menu选择事件
    handleSelectGroup ({ key }) {
      this.currentGroup = [key]
      this.query.groupId = key
    },
    async getGroupList () {
      const resp = await getGroupIdListByPlaceForAggregate({
        placeId: this.query.placeId,
        appId: this.query.appId,
        dateEnd: this.query.dateEnd,
        dateStart: this.query.dateStart
      })
      const { data = {} } = resp
      const { totalRequest = 0, groups = [] } = data
      this.totalRequest = totalRequest
      this.groupList = groups
      this.groupListTemp = JSON.parse(JSON.stringify(this.groupList))
      return resp
    },
    // 删除流量分组
    async handleDelectGroup (item) {
      const resp = await deleteGroupById({ groupId: item.id })
      if (resp.code === 200) {
        this.$message.success('删除成功！')
        this.getGroupList()
        if (item.id === this.query.groupId) {
          this.query.groupId = 'default'
          this.currentGroup = ['default']
        }
        setTimeout(() => {
          this.initSort()
        }, 1000)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.manual-operation-container {
  // min-width: 1280px;
  .config_main {
    min-height: calc(100vh - 210px);
    position: relative;
    .main_left_main {
      position: absolute;
      border-radius: 5px;
      margin-right: 10px;
      padding: 0 10px 10px 10px;
      background: #fff;
      display: flex;
      flex-direction: column;
      height: 70vh;
      transition: all 0.5s;
      .main_left_header {
        transition: all 0.5s;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
          font-size: 13px;
          font-weight: 600;
        }
        .add_group_btn {
          transition: all 0.5s;
          overflow: hidden;
        }
      }
      .main_left_group_list {
        flex-grow: 1;
        height: 0;
        position: relative;
        overflow-y: auto;
        overflow-x: hidden;
        &.ant-menu-vertical {
          border-right: none;
        }
        &::-webkit-scrollbar {
          width: 4px;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 5px;
          box-shadow: inset 0 0 5px fade(@primary-color, 3%);
          background: fade(@primary-color, 20%);
        }
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px fade(@primary-color, 3%);
          border-radius: 0;
          background: rgba(0, 0, 0, 0.1);
        }
        .ant-menu.ant-menu-vertical {
          .ant-menu-item {
            height: 35px;
            line-height: 35px;
            padding-right: 0;
            &.ant-menu-item-selected {
              background-color: fade(@primary-color, 5%);
              &::before {
                content: '';
                height: 100%;
                position: absolute;
                left: 0;
                border-radius: 5px;
                border-left: 3px solid @primary-color;
              }
            }
            .group_list_item {
              display: flex;
              justify-content: space-between;
              .group_name {
                width: 155px;
                font-size: 12px;
                flex-grow: 1;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .ab_dot {
                  display: inline-block;
                  height: 12px;
                  width: 24px;
                  vertical-align: super;
                  font-size: 12px;
                  line-height: 12px;
                  transform: scale(0.60) translate(0px, -8px);
                  text-align: center;
                  color: #fff;
                  background-color: red;
                  border-radius: 5px 5px 5px 0;
                }
                .request-chart {
                  height: 18px;
                  width: 18px;
                  min-width: 18px;
                  max-width: 18px;
                  display: inline-block;
                  position: relative;
                  margin-right: 5px;
                }
              }
              .group_operation {
                flex-grow: 1;
                transition: all 0.5s;
                overflow: hidden;
                display: flex;
                justify-content: flex-end;
                flex-direction: row;
                align-items: center;
                img{
                  width: 18px;
                  margin-right:5px;
                  cursor: pointer;
                  &:hover{
                    opacity: 0.7;
                  }
                }
                .icon {
                  min-width: 18px;
                  cursor: pointer;
                  transition: opacity 0.1s;
                  &:hover {
                    opacity: 0.5;
                  }
                  &.edit_icon {
                    color: #666;
                  }
                  &.delete_icon {
                    color: red;
                  }
                }
              }
            }
          }
        }
      }
      .main_left_footer {
        margin-top: 10px;
        cursor: pointer;
        height: 30px;
        font-size: 20px;
        line-height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #999;
        background: #eee;
        border-radius: 10px;
      }
    }
    .main_right {
      transition: all 0.5s;
      overflow: hidden;
      background: #fff;
      border-radius: 5px;
      padding: 15px;
    }
  }
}
::v-deep {
  .ant-menu-vertical {
    border-right: none;
  }
}
</style>
