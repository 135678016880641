<template>
  <a-modal v-model="addSourceVisible" title="批量添加广告源" @onCancel="handleCancel" @ok="handleSubmit" width="1200px">
    <a-spin tip="正在创建中，请耐心等待..." :spinning="isLoading">
      <div class="recommend-template-content">
        <div class="recommend-template-content-batch">
          <a-button type="primary" style="margin-right: 10px" @click="addSource"><a-icon type="plus" />广告源</a-button>
          <a-dropdown-button :trigger="['hover']" :disabled="selectedRowKeys.length === 0">
            批量操作
            <a-menu slot="overlay">
              <a-menu-item key="1" @click="handleBatchCopy"> <CopySvg style="margin-right: 8px" />批量复制 </a-menu-item>
              <a-menu-item key="2" @click="handleBatchDelete"> <a-icon type="delete" />批量删除 </a-menu-item>
            </a-menu>
          </a-dropdown-button>
        </div>
        <div class="table">
          <a-form-model ref="ruleForm" :model="formquery" v-bind="layout">
            <a-table
              size="middle"
              :rowKey="(record, index) => index"
              tableLayout="auto"
              :row-selection="{
                columnWidth: 50,
                selectedRowKeys: selectedRowKeys,
                onChange: onSelectChange,
                getCheckboxProps: (record) => {
                  return {
                    props: {
                      disabled: record.disabled
                    }
                  }
                }
              }"
              :data-source="formquery.sourceData"
              :pagination="false"
              :scroll="{ x: true, y: 400 }"
              bordered
            >
              <!-- 操作 -->
              <a-table-column align="left" title="操作" width="120px" :fixed="true">
                <template slot-scope="text, record, index">
                  <div v-if="!record.disabled">
                    <a-button size="small" type="link" @click="handleCopyItem(record, index)">复制</a-button>
                    <a-button size="small" type="link" @click="handleDeleteItem(index)">删除</a-button>
                  </div>
                  <div v-else>已添加</div>
                </template>
              </a-table-column>
              <!-- 广告位ID -->
              <a-table-column align="left" width="200px">
                <m-tips
                  slot="title"
                  :content="`在第三方广告平台创建广告位后生成的广告位ID。`"
                  title="广告位ID"
                  :width="150"
                  :showIcon="false"
                ></m-tips>
                <template slot-scope="text, record, index">
                  <a-button
                    type="link"
                    v-if="record.platAccountId && record.allowAutoCreate && record.isAutoCreate === '1' && !record.id"
                  >自动创建</a-button
                  >
                  <a-form-model-item
                    v-else
                    class="form_item"
                    :prop="`sourceData[${index}].platPlaceId`"
                    :rules="[
                      {
                        required: true,
                        message: '请输入广告位ID',
                        trigger: 'blur'
                      }
                    ]"
                  >
                    <a-input
                      :disabled="record.disabled"
                      placeholder="请输入广告位ID"
                      v-model.trim="record.platPlaceId"
                      style="width: 150px"
                    >
                    </a-input>
                  </a-form-model-item>
                </template>
              </a-table-column>
              <!-- 广告源名称 -->
              <a-table-column align="left" title="广告源名称" width="220px">
                <template slot-scope="text, record, index">
                  <a-form-model-item
                    class="form_item"
                    :prop="`sourceData[${index}].name`"
                    :rules="[
                      {
                        required: true,
                        message: '请输入广告源名称',
                        trigger: 'blur'
                      }
                    ]"
                  >
                    <a-input
                      :disabled="record.disabled"
                      placeholder="请输入广告源名称"
                      @change="handleChangeName(record)"
                      v-model.trim="record.name"
                      style="width: 190px"
                    >
                    </a-input
                    ></a-form-model-item>
                </template>
              </a-table-column>
              <!-- 价格类型 -->
              <a-table-column align="left" title="价格类型" width="150px">
                <template slot-scope="text, record, index">
                  <a-form-model-item
                    class="form_item"
                    :prop="`sourceData[${index}].settleType`"
                    :rules="[
                      {
                        required: true,
                        message: '请选择价格类型',
                        trigger: 'change'
                      }
                    ]"
                  >
                    <a-select
                      :disabled="record.disabled"
                      placeholder="请选择价格类型"
                      v-model="record.settleType"
                      style="width: 120px"
                      @change="changeSettleType($event, record)"
                      v-if="platsInfo[+record.platId]"
                    >
                      <a-select-option
                        v-for="(item, i) in platsInfo[record.platId].settleTypeListType === 0
                          ? settleTypeList1
                          : platsInfo[record.platId].settleTypeListType === 3
                            ? settleTypeList2
                            : platsInfo[record.platId].settleTypeListType === 1
                              ? settleTypeList3
                              : platsInfo[record.platId].settleTypeListType === 2
                                ? settleTypeList4
                                : []"
                        :key="i"
                        :value="item.id"
                      >{{ item.name }}</a-select-option
                      >
                    </a-select>
                  </a-form-model-item>
                </template>
              </a-table-column>
              <!-- 价格 -->
              <a-table-column align="left" width="150px">
                <m-tips
                  slot="title"
                  :content="`未开启自动创建广告源时，需在对应的广告平台设置目标价格eCPM，此处价格仅用于瀑布流排序和计算预估收益；设置穿山甲、百度目标价格，需要联系平台商务开通权限；当价格类型选择为服务端竞价时，此价格不会回传给广告平台，当广告平台出价低于竞价底价时，${title}将会过滤此出价结果，不返回广告填充。`"
                  title="排序价格"
                  :width="250"
                  :showIcon="false"
                ></m-tips>
                <template slot-scope="text, record, index">
                  <a-form-model-item
                    v-if="record.settleType === 0"
                    class="form_item"
                    :prop="`sourceData[${index}].bidfloor`"
                    :rules="[
                      {
                        required: true,
                        message: '请输入价格',
                        trigger: 'blur'
                      }
                    ]"
                  >
                    <a-input
                      type="number"
                      placeholder="请输入价格"
                      v-model.number.trim="record.bidfloor"
                      style="width: 120px"
                      :disabled="record.disabled"
                      @change="handleChangeBidfloor(record)"
                    >
                      <span slot="addonAfter">￥</span>
                    </a-input>
                  </a-form-model-item>
                  <a-button type="link" v-else-if="record.settleType === 1">服务端竞价</a-button>
                  <a-button type="link" v-else-if="record.settleType === 2">客户端竞价</a-button>
                </template>
              </a-table-column>
              <!-- 广告源状态 -->
              <a-table-column align="left" title="广告源状态" width="120px">
                <template slot-scope="text, record, index">
                  <a-form-model-item class="form_item" :prop="`sourceData[${index}].sts`">
                    <a-switch
                      :disabled="record.disabled"
                      :checked="record.sts === 'A'"
                      @change="changeSts($event, index)"
                    />
                  </a-form-model-item>
                </template>
              </a-table-column>
              <!-- 自动创建广告源 -->
              <a-table-column
                v-if="[1, 3, 4, 5, 6, 10].includes(+query.positionId) && [1, 2, 3, 6].includes(+query.platId)"
                align="left"
                width="180px"
              >
                <m-tips
                  slot="title"
                  :content="`${title}平台目前支持穿山甲、快手、优量汇三家平台的自动创建功能。开启自动创建广告源功能后，当您在${title}后台创建广告源时，${title}会自动在对应的第三方广告平台同步创建。`"
                  title="自动创建广告源"
                  :width="150"
                  :showIcon="false"
                ></m-tips>
                <template slot-scope="text, record, index">
                  <a-form-model-item class="form_item" :prop="`sourceData[${index}].isAutoCreate`">
                    <span style="line-height: 32px" v-if="![1, 2, 3, 6].includes(record.platId)">--</span>
                    <span style="line-height: 32px" v-else-if="record.id">已创建</span>
                    <span
                      style="line-height: 32px"
                      v-else-if="!record.allowAutoCreate"
                    >去<span
                      class="primary-text"
                      @click="toAllowAutoCreate(record, index)"
                    >授权开启</span
                    ></span
                    >
                    <a-switch
                      :disabled="record.disabled"
                      v-else
                      :checked="record.isAutoCreate === '1'"
                      @change="changeIsAutoCreate($event, index)"
                    />
                  </a-form-model-item>
                </template>
              </a-table-column>
              <!-- 广告位类型 -->
              <a-table-column
                align="left"
                title="广告位类型"
                width="250px"
                v-if="
                  +query.positionId === 10 &&
                    (+query.platId === 1 || +query.platId === 2 || +query.platId === 3 || +query.platId === 6)
                "
              >
                <!-- 穿山甲、优量汇、快手、百度展示广告类型 -->
                <template slot-scope="text, record, index">
                  <a-form-model-item
                    :rules="[{ required: true, message: '请选择广告位类型', trigger: 'change' }]"
                    :prop="`sourceData[${index}].adType`"
                  >
                    <a-select
                      style="width: 180px"
                      placeholder="请选择广告位类型"
                      v-model="record.adType"
                      @change="changeAdType(record, index)"
                      showSearch
                      :filter-option="filterOption"
                    >
                      <a-select-option
                        v-for="item in adTypeList"
                        :key="item.id"
                        :value="item.id"
                        showSearch
                        :filter-option="filterOption"
                      >{{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-model-item>
                </template>
              </a-table-column>
              <!-- 渲染方式 -->
              <a-table-column align="left" title="渲染方式" v-if="showAdStyle" width="220px">
                <template slot-scope="text, record, index">
                  <a-form-model-item
                    v-if="showAdStyleFormItem(record)"
                    class="table_item"
                    :prop="`sourceData[${index}].adStyle`"
                    :rules="[{ required: true, message: '请选择渲染方式', trigger: 'change' }]"
                  >
                    <a-select
                      style="margin: 0 15px 0 10px; width: 170px"
                      placeholder="请选择渲染方式"
                      v-model="record.adStyle"
                      showSearch
                      @change="changeAdStyle(record, index)"
                      :filter-option="filterOption"
                    >
                      <a-select-option
                        v-for="item in adStyleList(record)"
                        :key="item.id"
                        :value="item.id"
                        showSearch
                        :filter-option="filterOption"
                      >{{ item.name }}</a-select-option
                      >
                    </a-select>
                  </a-form-model-item>
                  <div style="width: 200px" v-else>-</div>
                </template>
              </a-table-column>
              <!-- 素材类型 -->
              <a-table-column v-if="showAutoCreateCol" align="left" title="素材类型" width="220px">
                <template slot-scope="text, record, index">
                  <div v-if="!showKsAdCrtTypeList(record) && !showCsjAdCrtTypeList(record)">--</div>
                  <a-form-model-item
                    v-else
                    class="form_item"
                    :prop="`sourceData[${index}].adCrtTypeList`"
                    :rules="[
                      {
                        required: true,
                        message: '请选择素材类型',
                        trigger: 'change'
                      }
                    ]"
                  >
                    <m-select-more
                      v-if="showKsAdCrtTypeList(record)"
                      style="width: 190px"
                      v-model="record.adCrtTypeList"
                      :disabled="record.disabled"
                      :allData="[
                        { name: '竖版视频', id: '1' },
                        { name: '横版视频', id: '2' },
                        { name: '竖版图片', id: '5' },
                        { name: '横版图片', id: '6' }
                      ]"
                      :searchById="false"
                      :showLabel="false"
                      :hasIcon="false"
                      :showId="false"
                      width="450px"
                      label="素材类型"
                    />
                    <a-select
                      v-else-if="showCsjAdCrtTypeList(record)"
                      placeholder="请选择素材类型"
                      v-model="record.adCrtTypeList[0]"
                      style="width: 200px"
                      :disabled="record.disabled"
                    >
                      <a-select-option value="1">仅图片</a-select-option>
                      <a-select-option value="2">仅视频</a-select-option>
                      <a-select-option value="3">图片+视频</a-select-option>
                    </a-select>
                  </a-form-model-item>
                </template>
              </a-table-column>
              <!-- 广告样式 -->
              <a-table-column v-if="showAutoCreateCol" align="left" title="广告样式" width="220px">
                <template slot-scope="text, record, index">
                  <a-form-model-item
                    class="form_item"
                    v-if="
                      record.allowAutoCreate &&
                        +record.platId === 1 &&
                        (+query.positionId === 4 || (+query.positionId === 10 && +record.adStyle === 2)) &&
                        record.isAutoCreate === '1' &&
                        !record.id
                    "
                    :prop="`sourceData[${index}].templateIdList`"
                    :rules="[
                      {
                        required: true,
                        message: '请选择广告样式',
                        trigger: 'change'
                      }
                    ]"
                  >
                    <m-select-more
                      style="width: 190px"
                      :disabled="record.disabled"
                      v-model="record.templateIdList"
                      :allData="[
                        { id: '1', name: '⼤图' },
                        { id: '2', name: '组图' },
                        { id: '3', name: '单图' },
                        { id: '4', name: '视频' },
                        { id: '5', name: '竖版视频' },
                        { id: '6', name: '竖图' }
                      ]"
                      :searchById="false"
                      :showLabel="false"
                      :hasIcon="false"
                      :showId="false"
                      width="450px"
                      label="广告样式"
                    />
                  </a-form-model-item>
                  <div v-else>--</div>
                </template>
              </a-table-column>
              <!-- 模版样式 -->
              <a-table-column v-if="showAutoCreateCol" align="left" title="模版样式" width="220px">
                <template slot-scope="text, record, index">
                  <a-form-model-item
                    class="form_item"
                    v-if="showTemplateIds(record)"
                    :prop="`sourceData[${index}].templateIdList`"
                    :rules="[
                      {
                        required: true,
                        message: '请选择模版样式',
                        trigger: 'change'
                      }
                    ]"
                  >
                    <m-select-more
                      style="width: 190px"
                      :disabled="record.disabled"
                      v-model="record.templateIdList"
                      :allData="getTemplateList(record)"
                      :searchById="false"
                      :showLabel="false"
                      :hasIcon="false"
                      :showId="false"
                      width="450px"
                      label="模版样式"
                    />
                  </a-form-model-item>
                  <div v-else>--</div>
                </template>
              </a-table-column>
              <!-- 失败原因 -->
              <a-table-column align="center" v-if="hasError" width="300px" fixed="right">
                <m-tips
                  slot="title"
                  :content="`此处显示瀑布流广告源创建失败的原因，请按提示原因修改，重新提交后则更新失败原因。`"
                  title="失败原因"
                  :width="150"
                  :showIcon="false"
                ></m-tips>
                <template slot-scope="text, record">
                  <span style="color: red">
                    {{ record.msg }}
                  </span>
                </template>
              </a-table-column>
            </a-table>
          </a-form-model>
        </div>
      </div>
      <AddAccountModal
        v-if="addAccountVisible"
        :visible="addAccountVisible"
        :platInfo="currentPlatInfo"
        :accountInfo="accountInfo"
        :isAddAcount="isAddAcount"
        @modalCancel="addAccountVisible = false"
        :query="query"
        @addNewAccount="getPlatAccounts"
        @editAccount="editAccount"
      />
    </a-spin>
  </a-modal>
</template>

<script>
// - 广告样式
//   - 穿山甲 原生自渲染

// - 模版样式

//   - 优量汇 原生自渲染
//   - 穿山甲 原生模版
//   - 优量汇 原生模版
//   - 快手 原生模版

// - 素材类型
//   - 快手 原生自渲染
//   - 穿山甲 原生模版
//   - 快手 原生模版

import { getPlatAccounts, batchBindPlat } from '@/api/aggregate'
import { getTemplateOrAdsouceList, batchAddSource2 } from '@/api/aggregateGuide'
import AddAccountModal from '../components/addAccountModal'
import { mapState } from 'vuex'
import mixGetList from '@/mixins/getList'
import { csjadTypeList, ylhadTypeList, ksadTypeList, bddTypeList, otadStyleList, bdadStyleList } from './list'
import CopySvg from '@/assets/icons/copy.svg?inline'
import { initAutoCreateFormDefault } from '@/utils/initSourceAutoCreateForm'

export default {
  name: 'GuidanceAddModal',
  components: { AddAccountModal, CopySvg },
  mixins: [mixGetList],
  data () {
    return {
      // table自动高度
      autoHeight: window.innerHeight - 300,
      // 账户弹窗是否显示
      addAccountVisible: false,
      // 是否是新增账户
      isAddAcount: false,
      // 当前点击的广告源所对应的广告平台信息
      currentPlatInfo: {
        name: '',
        id: 1,
        accessType: undefined
      },
      // 当前点击的广告源索引
      currentSourceIndex: 0,
      // 当前点击的广告源所对应的账户信息
      accountInfo: {},
      layout: {
        labelCol: { span: 2 },
        wrapperCol: { span: 20 }
      },
      query: {
        appId: undefined,
        placeId: undefined,
        positionId: undefined
      },
      formquery: {
        sourceData: []
      },
      platsInfo: {
      },
      settleTypeList1: [
        {
          name: '固价',
          id: 0
        }
      ],
      settleTypeList2: [
        {
          name: '固价',
          id: 0
        },
        {
          name: '服务端竞价',
          id: 1
        },
        {
          name: '客户端竞价',
          id: 2
        }
      ],
      settleTypeList3: [
        {
          name: '固价',
          id: 0
        },
        {
          name: '服务端竞价',
          id: 1
        }
      ],
      settleTypeList4: [
        {
          name: '固价',
          id: 0
        },
        {
          name: '客户端竞价',
          id: 2
        }
      ],
      // 批量数组（索引）
      selectedRowKeys: [],
      // 添加广告源的模版
      sourceTemp: {},
      hasError: false,
      isCompleted: false,
      isLoading: false
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    addinfo: {
      type: Object,
      default: () => ({})
    },
    betchaddquery: {
      type: Object,
      default: () => ({})
    }
  },
  async created () {
  },
  watch: {
    visible: {
      async handler () {
        if (this.visible) {
          this.hasError = false
          this.formquery.sourceData = []
          this.query = {
            appId: this.addinfo.appId,
            placeId: this.addinfo.placeId,
            positionId: this.addinfo.positionId,
            platAccountId: this.addinfo.platAccountId,
            platId: this.addinfo.platId
          }
          await this.getPlatList()
          this.platList = this.platList.filter((item) => item.accessType === '0')
          await this.getPlatAccounts()
          await this.getTemplateOrAdsouceList()
        }
      }
    }
  },
  computed: {
    showAutoCreateCol () {
      return [4, 6, 10].includes(+this.query.positionId)
    },
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      // 平台名
      title: (state) => state.autoweb.title,
      allRoutes: (state) => state.permission.allRoutes,
      origin: (state) => state.autoweb.origin
    }),
    addSourceVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    },
    adTypeList () {
      const adTypeObj = {
        1: csjadTypeList,
        2: ylhadTypeList,
        3: ksadTypeList,
        6: bddTypeList
      }
      return adTypeObj[+this.query.platId]
    },
    // 渲染方式展示
    showAdStyle () {
      return +this.query.positionId === 10
    }
  },
  methods: {
    //  展示素材样式1
    showKsAdCrtTypeList (record) {
      const positionId = +this.query.positionId
      const visb = +record.isAutoCreate
      const platId = +this.query.platId
      const adstyle = +record.adStyle
      const item = record
      if (record.id) {
        return false
      }
      if (!+record.allowAutoCreate || !visb) {
        return false
      }
      // 穿山甲 混合 （draw ｜｜ 贴片）
      if (platId === 1 && positionId === 10 && (item.adType === 2 || item.adType === 3)) {
        return false
      }
      // 快手 混合 draw
      if (platId === 3 && positionId === 10 && item.adType === 2) {
        return false
      }
      if (visb) {
        // 快手 and （原生模板6 or 原生自渲染4 or (原生混合 && adSytle有值)）
        if ((positionId === 4 || positionId === 6 || (positionId === 10 && adstyle)) && platId === 3) {
          return true
        }
      }
    },
    //  展示素材样式2
    showCsjAdCrtTypeList (record) {
      const positionId = +this.query.positionId
      const visb = +record.isAutoCreate
      const platId = +this.query.platId
      const adstyle = +record.adStyle
      const item = record
      if (record.id) {
        return false
      }
      if (!+record.allowAutoCreate || !visb) {
        return false
      }
      // 穿山甲 混合 (draw ｜｜ 贴片) 不显示
      if (platId === 1 && positionId === 10 && (item.adType === 2 || item.adType === 3)) {
        return false
      }
      // 快手 混合 draw 不显示
      if (platId === 3 && positionId === 10 && item.adType === 2) {
        return false
      }
      if (visb) {
        //   穿山甲 and (原生模板6 or (原生混合10  && adStyle===1有值))
        if ((positionId === 6 || (positionId === 10 && adstyle === 1)) && platId === 1) {
          return true
        }
      }
    },
    // 搜索框
    filterOption (input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    initAutoCreateFormDefault,
    showAdStyleFormItem (record) {
      return !(record.platId === 6 && (record.adType === 2 || record.adType === 3))
    },
    // 选择渲染方式
    changeAdStyle (e, index) {
      this.initAutoCreateFormDefault({ id: e.platId }, e, this.query.positionId)
    },
    changeAdType (e, index) {
      this.formquery.sourceData[index].adType = e.adType
      // 看是否有模版渲染
      const inStyle = this.adStyleList(e).some((item) => item.id === 1)
      this.formquery.sourceData[index].adStyle = inStyle ? 1 : 2
      this.initAutoCreateFormDefault({ id: e.platId }, e, this.query.positionId)
    },
    // 渲染方式
    adStyleList (item) {
      let list = []
      // 穿山甲 广告位类型选择贴片时 ｜｜ 优量汇 广告位类型选择沉浸式视频流时
      const type = (item.adType === 3 && item.platId === 1) || (item.adType === 4 && item.platId === 2)
      list = type ? (list = otadStyleList.filter((item) => item.id === 2)) : otadStyleList
      // 百度
      if (item.platId === 6) {
        list = bdadStyleList
      }
      // 优量汇 选择沉浸式 || 穿山甲贴片
      if ((item.adType === 4 && item.platId === 2) || (item.adType === 3 && item.platId === 1)) {
        list = [{ name: '自渲染', id: 2 }]
      }
      // 快手 draw
      if (item.adType === 2 && item.platId === 3) {
        list = [{ name: '模版渲染', id: 1 }]
      }
      return list
    },
    handleCancel () {
      this.addSourceVisible = false
    },
    // 获取并初始化广告源列表
    async getTemplateOrAdsouceList () {
      const { data = [] } = await getTemplateOrAdsouceList({
        positionId: this.query.positionId,
        appId: this.query.appId,
        platId: this.query.platId,
        platAccountId: this.query.platAccountId,
        placeId: this.query.placeId
      })
      this.formquery.sourceData = []
      data.forEach((item, index) => {
        // if (index > 3) return
        this.formquery.sourceData.push(item)
        const currentAccount = this.platsInfo[+item.platId].platAccountList[0]
        // 广告源基础信息
        this.formquery.sourceData[index].position = this.query.positionId
        this.formquery.sourceData[index].appId = this.query.appId
        this.formquery.sourceData[index].placeId = this.query.placeId
        // this.formquery.sourceData[index].groupId = this.query.groupId
        // this.formquery.sourceData[index].testId = this.query.testId
        // 每个广告源唯一标识（批量绑定使用）
        this.formquery.sourceData[index].key = '' + Math.random()
        // 账户
        this.$set(this.formquery.sourceData[index], 'platAccountId', currentAccount.id)
        // 应用ID
        this.$set(this.formquery.sourceData[index], 'platAppId', currentAccount.platAppId)
        // 账户是否绑定过应用ID
        this.$set(this.formquery.sourceData[index], 'hasPltAppId', !!currentAccount.platAppId)
        // 账户是否开启自动创建广告源的权限
        this.$set(this.formquery.sourceData[index], 'allowAutoCreate', +currentAccount.isAutoCreate === 1)
        // 广告源名称模版
        this.$set(this.formquery.sourceData[index], 'nameTemplete', item.name)
        // 广告源名称是否修改过
        this.$set(this.formquery.sourceData[index], 'hasChangeName', false)
        // 已配置过的广告源的id
        this.$set(this.formquery.sourceData[index], 'id', item.id)
        // const suffix =
        //   item.settleType === 0
        //     ? typeof item.bidfloor === 'number'
        //       ? item.bidfloor
        //       : ''
        //     : item.settleType === 1
        //     ? '服务端竞价'
        //     : '客户端竞价'
        // const name = item.name + '-' + suffix
        const name = item.name
        // 广告源名称
        this.$set(this.formquery.sourceData[index], 'name', name)
        // 广告源ID（广告位ID）
        this.$set(this.formquery.sourceData[index], 'platPlaceId', item.platPlaceId)
        // 竞价类型
        this.$set(this.formquery.sourceData[index], 'settleType', item.settleType)
        // 价格
        this.$set(this.formquery.sourceData[index], 'bidfloor', item.bidfloor)
        // 广告源状态
        this.$set(this.formquery.sourceData[index], 'sts', 'A')
        this.$set(this.formquery.sourceData[index], 'adStyle', +item.adStyle || 1)
        this.$set(this.formquery.sourceData[index], 'adType', +item.adType || 1)
        // 是否开启自动创建广告源
        this.$set(
          this.formquery.sourceData[index],
          'isAutoCreate',
          +this.query.positionId === 2 ? 0 : +currentAccount.isAutoCreate === 1 ? '1' : +currentAccount.isAutoCreate === 0 ? '0' : '0'
        )
        // 广告样式
        this.$set(this.formquery.sourceData[index], 'templateIdList', [])
        // 素材类型
        this.$set(this.formquery.sourceData[index], 'adCrtTypeList', [])
        this.initAutoCreateFormDefault(
          { id: +this.query.platId },
          this.formquery.sourceData[index],
          this.query.positionId
        )
      })
      // 得到新增广告源模版
      if (this.formquery.sourceData[0]) {
        for (const key in this.formquery.sourceData[0]) {
          this.$set(this.sourceTemp, key, undefined)
        }
        this.sourceTemp.platAccountId = this.formquery.sourceData[0].platAccountId
        this.sourceTemp.platAppId = this.formquery.sourceData[0].platAppId
        this.sourceTemp.isAutoCreate = this.formquery.sourceData[0].isAutoCreate
        this.sourceTemp.allowAutoCreate = this.formquery.sourceData[0].allowAutoCreate
      }
    },
    // 获取所有广告平台的账户列表
    async getPlatAccounts () {
      const { data = [] } = await getPlatAccounts({
        appId: this.query.appId,
        platId: this.query.platId
      })
      // 更行账号列表
      this.platsInfo[this.query.platId] = {}
      const platInfo = this.platList.find((item) => +item.id === +this.query.platId)
      this.platsInfo[this.query.platId].settleTypeListType = +platInfo.settleType
      this.platsInfo[this.query.platId].platAccountList = data
    },
    // 账户修改成功回调
    async editAccount () {
      // 更新所对应的广告平台下的账户列表
      const { data = [] } = await getPlatAccounts({
        appId: this.query.appId,
        platId: this.query.platId
      })
      this.platsInfo[+this.query.platId].platAccountList = data
      // 该广告源绑定的账户
      const currentAccount = this.platsInfo[+this.query.platId].platAccountList.find(
        (item) => +item.id === +this.query.platAccountId
      )
      // 判断该账号是否允许自动创建广告源
      const allowAutoCreate = currentAccount.isAutoCreate === '1'
      this.formquery.sourceData.forEach((item) => {
        item.allowAutoCreate = allowAutoCreate
      })
    },
    // 单个添加
    addSource () {
      // 通过添加模版添加
      const newSource = JSON.parse(JSON.stringify(this.sourceTemp))
      // for (const key in this.sourceTemp) {
      //   this.$set(newSource, key, this.sourceTemp[key])
      // }
      // newSource.id = undefined
      // // 默认固价
      // newSource.settleType = 0
      // // 广告样式  模版样式
      // newSource.getTemplateList = []
      // // 素材类型
      // newSource.adCrtTypeList = []
      // newSource.sts = 'A'
      // newSource.hasChangeName = true
      // newSource.nameTemplete = ''
      // // newSource.platAccountId = undefined
      // // newSource.platAppId = undefined
      // newSource.appId = this.query.appId
      // newSource.placeId = this.query.placeId
      // newSource.positionId = this.query.positionId
      // newSource.platAppId = this.query.platAppId
      // newSource.isAutoCreate = this.sourceTemp.isAutoCreate
      // newSource.allowAutoCreate = this.sourceTemp.allowAutoCreate
      // newSource.key = '' + Math.random()
      // newSource.platId = this.query.platId
      // newSource.platAccountId = this.query.platAccountId
      const currentAccount = this.platsInfo[+this.query.platId].platAccountList.find(
        (item) => +item.id === +this.query.platAccountId
      )
      // 广告源基础信息
      newSource.position = this.query.positionId
      newSource.appId = this.query.appId
      newSource.placeId = this.query.placeId
      newSource.platId = this.query.platId
      // 每个广告源唯一标识（批量绑定使用）
      newSource.key = '' + Math.random()
      // 账户
      this.$set(newSource, 'platAccountId', currentAccount.id)
      // 应用ID
      this.$set(newSource, 'platAppId', currentAccount.platAppId)
      // 账户是否绑定过应用ID
      this.$set(newSource, 'hasPltAppId', !!currentAccount.platAppId)
      // 账户是否开启自动创建广告源的权限
      this.$set(newSource, 'allowAutoCreate', +currentAccount.isAutoCreate === 1)
      // 广告源名称模版
      this.$set(newSource, 'nameTemplete', '')
      // 广告源名称是否修改过
      this.$set(newSource, 'hasChangeName', false)
      // 已配置过的广告源的id
      this.$set(newSource, 'id', undefined)
      // 广告源名称
      this.$set(newSource, 'name', '')
      // 广告源ID（广告位ID）
      this.$set(newSource, 'platPlaceId', undefined)
      this.$set(newSource, 'adStyle', 1)
      this.$set(newSource, 'adType', 1)
      // 竞价类型
      this.$set(newSource, 'settleType', 0)
      // 价格
      this.$set(newSource, 'bidfloor', undefined)
      // 广告源状态
      this.$set(newSource, 'sts', 'A')
      // 是否开启自动创建广告源
      this.$set(
        newSource,
        'isAutoCreate',
        +currentAccount.isAutoCreate === 1 ? '1' : +currentAccount.isAutoCreate === 0 ? '0' : '0'
      )
      // 广告样式
      this.$set(newSource, 'templateIdList', [])
      // 素材类型
      this.$set(newSource, 'adCrtTypeList', [])
      this.initAutoCreateFormDefault({ id: +this.query.platId }, newSource, this.query.positionId)
      this.formquery.sourceData.push(newSource)
      this.$message.success('添加成功！')
    },
    // 批量复制广告源
    handleBatchCopy () {
      const copyArr = []
      this.selectedRowKeys.forEach((index) => {
        copyArr.push(JSON.parse(JSON.stringify(this.formquery.sourceData[index])))
        copyArr[index].key = '' + Math.random()
      })
      this.formquery.sourceData = [...this.formquery.sourceData, ...copyArr]
      this.selectedRowKeys = []
      // 复制成功
      this.$message.success('复制成功')
    },
    // 批量删除广告源
    handleBatchDelete () {
      this.$confirm({
        title: '提示',
        maskClosable: true,
        content: () => {
          return (
            <div class="method-modal" style="height:100px;display:flex;padding:30px">
              <div>
                <a-icon
                  style="color: #fa8b15;font-size: 22px;margin-right: 20px;line-height:30px"
                  type="exclamation-circle"
                />
              </div>
              <span>确定删除已选中的广告源？</span>
            </div>
          )
        },
        onOk: () => {
          this.formquery.sourceData = this.formquery.sourceData.filter(
            (item, index) => !this.selectedRowKeys.includes(index)
          )
          this.selectedRowKeys = []
          this.$message.success('删除成功！')
        },
        icon: () => {
          return ''
        },
        class: 'method-modal-wrapper'
      })
    },
    onSelectChange (selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    // 单个复制
    handleCopyItem (record, index) {
      const copyItem = {}
      for (const key in record) {
        this.$set(copyItem, key, record[key] !== undefined ? JSON.parse(JSON.stringify(record[key])) : undefined)
      }
      copyItem.key = '' + Math.random()
      copyItem.id = undefined
      this.formquery.sourceData.push(copyItem)
      this.selectedRowKeys = []
      this.$message.success('复制成功！')
    },
    // 单个删除
    handleDeleteItem (index) {
      this.formquery.sourceData.splice(index, 1)
      this.selectedRowKeys = []
    },
    // 修改名称后改变hasChangeName标志
    handleChangeName (record) {
      record.hasChangeName = true
    },
    // 修改价格类型对广告源名称进行处理
    changeSettleType (e, record) {
      if (record.hasChangeName) return
      const suffix =
        +e === 0 ? (typeof record.bidfloor === 'number' ? record.bidfloor : '') : +e === 1 ? '服务端竞价' : '客户端竞价'
      record.name = record.nameTemplete + '-' + suffix
    },
    // 价格处理，价格修改对于广告源名称进行处理
    handleChangeBidfloor (record) {
      if (record.bidfloor < 0.01 && record.bidfloor !== 0) {
        record.bidfloor = 0.01
      }
      !record.hasChangeName && !!record.nameTemplete &&
        (record.name = record.nameTemplete + '-' + (typeof record.bidfloor === 'number' ? record.bidfloor : ''))
    },
    // 修改广告源状态
    changeSts (e, index) {
      this.formquery.sourceData[index].sts = e ? 'A' : 'S'
    },
    // 修改广告源是否允许自动创建
    changeIsAutoCreate (e, index) {
      this.formquery.sourceData[index].isAutoCreate = e ? '1' : '0'
    },
    toAllowAutoCreate (record, index) {
      this.currentSourceIndex = index
      this.getPlatAndAccountInfo(record)
      this.isAddAcount = false
      this.addAccountVisible = true
    },
    // 获取当前点击行的账户信息和平台信息
    getPlatAndAccountInfo (record) {
      const currentPlat = this.platsInfo[record.platId]
      this.accountInfo = currentPlat.platAccountList.find((item) => item.id === record.platAccountId)
      this.currentPlatInfo = {
        name: currentPlat.platName,
        accessType: currentPlat.accessType,
        id: record.platId
      }
    },
    // 是否显示模版样式
    showTemplateIds (record) {
      const positionId = +this.query.positionId
      const visb = +record.isAutoCreate
      const platId = +this.query.platId
      const adstyle = +record.adStyle
      if (record.id) {
        return false
      }
      if (!+record.allowAutoCreate || !visb) {
        return false
      }
      // draw 混合模版 穿山甲 不显示
      if ((record.adType === 2 || record.adType === 3) && positionId === 10 && platId === 1) {
        return false
      }
      // draw 混合模版 快手 不显示
      if (record.adType === 2 && positionId === 10 && platId === 3) {
        return false
      }
      if (visb) {
        // 优量汇 (原生自渲染 || (原生混合10 && adstyle === 2))
        if (platId === 2 && (positionId === 4 || (positionId === 10 && adstyle === 2))) {
          return true
        } else if (
          (positionId === 6 || (positionId === 10 && adstyle === 1)) &&
          (platId === 1 || platId === 2 || platId === 3)
        ) {
          // (原生模板6 || (原生混合10 && adstyle === 1)) (穿山甲/优量汇/快手)
          return true
        }
      }
    },
    // 模版样式
    getTemplateList (record) {
      // (原生模板6 or (原生混合10 && adstyle === 1)) 优量汇
      if (
        (+this.query.positionId === 6 || (+this.query.positionId === 10 && +record.adStyle === 1)) &&
        +this.query.platId === 2
      ) {
        return [
          { id: '1', name: '上图下文（支持视频）' },
          { id: '2', name: '上文下图（支持视频）' },
          { id: '3', name: '左图右文' },
          { id: '4', name: '左文右图' },
          { id: '5', name: '双图双文（支持视频）' },
          { id: '6', name: '纯图竖版' },
          { id: '7', name: '纯图横版（支持视频）' },
          { id: '8', name: '三小图双文' },
          { id: '9', name: '上文下图-文字浮层' },
          { id: '10', name: '上图下文-文字浮层' },
          { id: '11', name: '单图单文-文字浮层' }
        ]
      } else if (
        // 原生模版6 ｜｜ （混合 && 模版渲染adstyle） && 穿山甲
        (+this.query.positionId === 6 || (+this.query.positionId === 10 && +record.adStyle === 1)) &&
        this.query.platId === 1
      ) {
        if ((record.adCrtTypeList && record.adCrtTypeList.length === 0) || +record.adCrtTypeList[0] !== 2) {
          return [
            { id: '1', name: '上⽂下图' },
            { id: '2', name: '上图下⽂' },
            { id: '3', name: '⽂字浮层' },
            { id: '4', name: '竖版' },
            { id: '5', name: '左图右⽂' },
            { id: '6', name: '左⽂右图' },
            { id: '7', name: '三图' }
          ]
        } else if (+record.adCrtTypeList[0] === 2) {
          // 穿山甲 原生自渲染
          return [
            { id: '1', name: '上⽂下图' },
            { id: '2', name: '上图下⽂' },
            { id: '3', name: '⽂字浮层' },
            { id: '4', name: '竖版' }
          ]
        }
      } else if (
        (+this.query.positionId === 4 || (+this.query.positionId === 10 && +record.adStyle === 2)) &&
        +this.query.platId === 2
      ) {
        // 优量汇 and (原生自渲染4 or (原生混合10 && adStyle === 2))
        const line1 = [
          { id: '1', name: '16:9 图片' },
          { id: '2', name: '16:9 视频' },
          { id: '3', name: '3:2 三小图' },
          { id: '4', name: '9:16 图片' },
          { id: '5', name: '9:16 视频' }
        ]
        let xLine = []
        // 选择 沉浸式视频流时没有3:2三小图
        if (+record.scene === 4 || record.adType === 4) {
          xLine = line1.filter((item) => item.id !== '3')
        } else if (+record.scene === 1 || record.adType === 1) {
          // 仅选择信息流广告时 才有20:3图片
          xLine = [...line1, { id: '6', name: '20:3 图片' }]
        } else {
          xLine = line1
        }
        return xLine
      } else if (
        (+this.query.positionId === 6 || (+this.query.positionId === 10 && +record.adStyle === 1)) &&
        +this.query.platId === 3
      ) {
        // 快手 and (原生模板6 or (原生混合10 && adStyle === 1)) 1:竖版视频 2:横版视频 5: 竖版图片 6: 横版图片
        const obj3 = [
          { id: '1', name: '大图(支持横版图片/视频)' },
          { id: '2', name: '左文右图(支持横版图片)' },
          { id: '3', name: '左图右文(支持横版图片)' },
          { id: '4', name: '上文下图(支持横版图片/视频)' },
          { id: '5', name: '上图下文(支持横版图片/视频)' },
          { id: '14', name: '三图(支持横版图片)' },
          { id: '15', name: '三图组合(支持横版图片)' },
          { id: '16', name: '橱窗(支持横版图片)' }
        ]
        const obj = [
          { id: '17', name: '上文下图(支持竖版图片/视频)' },
          { id: '18', name: '上图下文(支持竖版图片/视频)' },
          { id: '19', name: '大图（支持竖版图片/视频' }
        ]
        const obj1 = [
          { id: '1', name: '大图(支持横版图片/视频)' },
          { id: '4', name: '上文下图(支持横版图片/视频)' },
          { id: '5', name: '上图下文(支持横版图片/视频)' }
        ]
        const list = {
          1: obj,
          5: obj,
          2: obj1,
          6: obj3
        }
        let arr = []
        record.adCrtTypeList.length > 1 &&
          record.adCrtTypeList.forEach((item) => {
            arr = [...arr, ...list[item]]
          })
        return [...new Map(arr.map((item) => [item.id, item])).values()]
      }
      return []
    },
    // 提交
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.isLoading = true
          const allBind = await this.batchBindPlatAppId()
          if (!allBind) {
            this.isLoading = false
            return
          }
          const allAdd = await this.batchAddSource2()
          if (!allAdd) {
            this.isLoading = false
            return
          }
          this.isCompleted = true
          this.$message.success('添加成功！')
          this.addSourceVisible = false
          this.$emit('addSourceSuccess')
          this.isLoading = false
        } else {
          this.$message.warning('请完成当前表单填写！')
        }
      })
    },
    async batchAddSource2 () {
      const sourceList = this.formquery.sourceData.filter((item) => !item.disabled)
      if (sourceList.length === 0) {
        return true
      }
      const resp = await batchAddSource2({
        sourceData: sourceList
      })
      if (resp.code === 200) {
        return true
      } else {
        const msgList = resp.data || []
        msgList.forEach((item) => {
          const errorIndex = this.formquery.sourceData.findIndex((child, index) => +index === +item.index)
          this.$set(this.formquery.sourceData[errorIndex], 'msg', item.msg === 'success' ? '' : item.msg)
          this.$set(this.formquery.sourceData[errorIndex], 'disabled', item.msg === 'success')
        })
        const successSource = this.formquery.sourceData.filter((success) => success.disabled)
        const failSource = this.formquery.sourceData.filter((success) => !success.disabled)
        this.formquery.sourceData = [...failSource, ...successSource]
        this.hasError = true
        return false
      }
    },
    async batchBindPlatAppId () {
      const appPlatLinkList = this.formquery.sourceData
        .filter((item) => !item.disabled && !item.hasPltAppId)
        .map((item) => {
          return {
            appId: item.appId,
            appKey: '',
            platId: item.platId,
            platAccountId: item.platAccountId,
            platAppId: item.platAppId,
            index: item.key
          }
        })
      if (appPlatLinkList.length === 0) return true
      const bindquery = {
        appPlatLinkList
      }
      const resp = await batchBindPlat(bindquery)
      if (resp.code === 200 || resp.code === 201) {
        await this.getPlatAccounts()
        this.formquery.sourceData.forEach((source) => {
          const currentAccount = this.platsInfo[source.platId].platAccountList.find(
            (account) => account.id === source.platAccountId
          )
          if (currentAccount.platAppId === source.platAppId) {
            source.hasPltAppId = true
          }
        })
      }
      if (resp.code === 200) {
        return true
      } else {
        const msgList = resp.data
        this.setBindMsg(msgList)
        this.hasError = true
        return false
      }
    },
    // 设置未成功的响应信息
    setBindMsg (msgList) {
      msgList.forEach((item) => {
        const errorIndex = this.formquery.sourceData.findIndex((child) => child.key === item.index)
        this.$set(this.formquery.sourceData[errorIndex], 'msg', item.msg === 'success' ? '' : item.msg)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.recommend-template-content {
  min-height: 500px;
  border-radius: 10px;
  background: #fff;
  margin: 0 10px;
  .recommend-template-content-batch {
    padding: 0 0 20px 5px;
  }
}
::v-deep.recommend-template-content {
  .form_item {
    display: flex;
    .ant-form-explain {
      width: 120px;
    }
  }
  .ant-table-content {
    .ant-table-body-inner .ant-table-fixed .ant-table-row {
      height: 70px !important;
    }
    .ant-table-scroll .ant-table-body .ant-table-fixed .ant-table-tbody {
      tr {
        height: 70px;
        td {
          padding: 0 12px;
          .ant-form-item {
            display: flex;
            margin-bottom: 0;
            height: 40px;
            margin-top: 5px;
            .ant-form-item-control {
              line-height: 20px;
            }
            .ant-form-explain,
            .ant-form-extra {
              min-height: 16px;
              line-height: 16px;
              font-size: 12px;
            }
            .ant-select {
              padding: 0;
            }
            .ant-switch {
              margin-top: 8px;
            }
          }
        }
      }
    }
  }
  .ant-btn-group.ant-dropdown-button {
    .ant-btn {
      border-radius: 5px 0 0 5px;
      &.ant-dropdown-trigger {
        border-radius: 0 5px 5px 0;
      }
    }
  }
}
</style>

<style lang="less">
.method-modal-wrapper {
  &.ant-modal-confirm.ant-modal-confirm-confirm .ant-modal{
    margin-top: 200px;
  }
  .ant-modal-body {
    padding: 0 !important;
  }
  .ant-modal-confirm-body-wrapper {
    .ant-modal-confirm-title {
      height: 50px;
      line-height: 50px;
      padding: 0 10px;
      border-bottom: 1px solid #eee;
    }
    .ant-modal-confirm-content {
      border-bottom: 1px solid #eee;
    }
    .ant-modal-confirm-btns {
      margin-top: 14px;
      margin-bottom: 12px;
      margin-right: 10px;
    }
  }
}
</style>
