<template>
  <a-modal
    :maskClosable="false"
    v-model="thirdlyVisible"
    title="选择运营方式"
    @onCancel="handleCancel"
    width="700px"
  >
    <template slot="footer">
      <a-button key="submit" type="primary" @click="handleSubmit"> 完成 </a-button>
    </template>
    <div class="operator-type-container">
      <div class="operator-item" @click="operatorType = item.value" :class="{active: item.value === operatorType}" v-for="item in typeList" :key="item.value">
        <h3>{{ item.title }}</h3>
        <span class="desc">{{ item.desc }}</span>
        <div class="img">
          <img :src="item.imgUrl" alt="">
        </div>
        <span class="hint">{{ item.hint }}</span>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { setOperatorType } from '@/api/aggregateGuide'
export default {
  data () {
    return {
      operatorType: 1,
      typeList: [
        {
          value: 1,
          title: 'AI自动运营',
          desc: '自动创建联盟广告源，平台提供瀑布流模板，AI实时监控广告数据，自动调整策略。',
          hint: '此方式适合没有过多时间关注广告数据情况使',
          imgUrl: require('@/assets/images/aggregate/guidance/type1.png')
        },
        {
          value: 2,
          title: '手动运营',
          desc: '需要手动配置瀑布流内容，人工关注广告数据，根据经验来调整广告策略。',
          hint: '此方式适合没有过多时间关注广告数据情况使',
          imgUrl: require('@/assets/images/aggregate/guidance/type2.png')
        }
      ]
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    query: {
      default: () => ({}),
      type: Object
    }
  },
  watch: {
    visible: {
      handler (val) {
        if (val) {
          this.operatorType = 1
        }
      }
    }
  },
  computed: {
    thirdlyVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  methods: {
    handleCancel () {
      this.thirdlyVisible = false
    },
    async handleSubmit () {
      const resp = await setOperatorType({
        operatorType: this.operatorType,
        placeId: this.query.placeId
      })
      if (resp.code === 200) {
        this.$message.success('设置成功')
        this.thirdlyVisible = false
        this.$emit('selectOperatorTypeSuccess', this.operatorType)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.operator-type-container{
  color: #f6f6f6;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
  .operator-item{
    user-select: none;
    border: 1px solid;
    width: 50%;
    height: 300px;
    margin: 10px;
    cursor: pointer;
    border-radius: 6px;
    padding: 20px;
    position: relative;
    background-color: #f6f6f6;
    display: flex;
    flex-direction: column;
    h3{
      line-height: 20px;
      font-size: 14.25px;
      color: #000;
    }
    .desc, .hint{
      font-size: 12px;
      color: #000;
      line-height: 20px;
    }
    .img{
      height: 160px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 10px;
      margin-top: 10px;
      img{
        // width: 140px;
        height: 140px;
      }
    }
    &.active{
      color: @primary-color;
      background-color: #F3F6FF;
      h3, .desc, .hint{
        color: @primary-color!important;
      }
    }
  }
}
</style>
