var render = function render(){var _vm=this,_c=_vm._self._c;return (
    (+_vm.platInfo.accessType === 1 &&
      (+_vm.positionId === 1 ||
      +_vm.positionId === 3 ||
      (+_vm.positionId === 10 && +_vm.formquery.adStyle === 1) ||
      +_vm.positionId === 6)) ||
      ((((+_vm.positionId === 1 || +_vm.positionId === 3) && +_vm.formquery.isAutoDrawing === 1 && _vm.showIsAutoDrawing(_vm.formquery, _vm.positionId, _vm.formquery.platId)) ||
      +_vm.positionId === 4 ||
      (+_vm.positionId === 10 && +_vm.formquery.adStyle === 2)) &&
      (+_vm.formquery.platId === 1 ||
      +_vm.formquery.platId === 2 ||
      +_vm.formquery.platId === 3 ||
      +_vm.formquery.platId === 4 ||
      +_vm.formquery.platId === 6 ||
      +_vm.formquery.platId === 10 ||
      +_vm.formquery.platId === 12 ||
      +_vm.formquery.platId === 14 ||
      +_vm.formquery.platId === 19)) ||
      (+_vm.positionId === 10 && +_vm.formquery.adStyle === 2 && +_vm.formquery.platId === 0) ||
      (+_vm.positionId === 5 && +_vm.formquery.platId === 0)
  )?_c('div',{staticClass:"public-source-form-container"},[_c('a-form-model-item',{attrs:{"prop":"hotspot"}},[_c('m-tips',{attrs:{"slot":"label","content":`${_vm.title}针对自渲染的开屏、插屏、信息流场景，在满足合规要求的前提下，提供了丰富的广告交互样式。${_vm.title}版本2.6.5及之上版本支持该配置，部分交互需在SDK版本2.7.5及之上支持。原生混合-自渲染和激励视频样式需要在SDK版本2.7.7及以上支持该功能`,"title":'广告交互',"width":200},slot:"label"}),_c('a-radio-group',{attrs:{"name":"radioGroup"},on:{"change":_vm.changeHotspot},model:{value:(_vm.formquery.hotspot),callback:function ($$v) {_vm.$set(_vm.formquery, "hotspot", $$v)},expression:"formquery.hotspot"}},[_c('a-radio',{attrs:{"value":"A"}},[_vm._v(" 开启 ")]),_c('a-radio',{attrs:{"value":"S"}},[_vm._v(" 关闭 ")])],1)],1),(
      _vm.formquery.hotspot === 'A' &&
        ((+_vm.platInfo.accessType === 1 &&
        (+_vm.positionId === 1 ||
        +_vm.positionId === 3 ||
        (+_vm.positionId === 10 && +_vm.formquery.adStyle === 1) ||
        +_vm.positionId === 6)) ||
        ((((+_vm.positionId === 1 || +_vm.positionId === 3) && +_vm.formquery.isAutoDrawing === 1) ||
        +_vm.positionId === 4 ||
        (+_vm.positionId === 10 && +_vm.formquery.adStyle === 2)) &&
        (+_vm.formquery.platId === 1 ||
        +_vm.formquery.platId === 2 ||
        +_vm.formquery.platId === 3 ||
        +_vm.formquery.platId === 4 ||
        +_vm.formquery.platId === 6 ||
        +_vm.formquery.platId === 10 ||
        +_vm.formquery.platId === 12 ||
        +_vm.formquery.platId === 14 ||
        +_vm.formquery.platId === 19)) ||
        (+_vm.positionId === 10 && +_vm.formquery.adStyle === 2 && +_vm.formquery.platId === 0) ||
        (+_vm.positionId === 5 && +_vm.formquery.platId === 0))
    )?_c('a-form-model-item',{attrs:{"label":"广告交互样式","prop":"hotspotType"}},[_c('a-radio-group',{attrs:{"name":"radioGroup"},on:{"change":_vm.changeHotspotType},model:{value:(_vm.formquery.hotspotType),callback:function ($$v) {_vm.$set(_vm.formquery, "hotspotType", $$v)},expression:"formquery.hotspotType"}},[(+_vm.positionId === 1)?_c('a-radio',{attrs:{"value":1}},[_vm._v(" 默认 ")]):_vm._e(),(![1, 2, 3, 6].includes(+_vm.platInfo.id))?_c('a-radio',{attrs:{"value":2}},[_vm._v(" 摇一摇 ")]):_vm._e(),(+_vm.positionId === 1)?_c('a-radio',{attrs:{"value":3}},[_vm._v(" 圆形+触手 ")]):_vm._e(),(+_vm.positionId === 1 && +_vm.platInfo.accessType === 1)?_c('a-radio',{attrs:{"value":4}},[_vm._v(" 前倾 ")]):_vm._e(),(+_vm.positionId === 1 && +_vm.platInfo.accessType === 1)?_c('a-radio',{attrs:{"value":5}},[_vm._v(" 滑动 ")]):_vm._e(),(+_vm.positionId === 1 && +_vm.platInfo.accessType === 1)?_c('a-radio',{attrs:{"value":6}},[_vm._v(" 蒙层&摇一摇 ")]):_vm._e(),(+_vm.positionId === 1 && +_vm.platInfo.accessType === 1)?_c('a-radio',{attrs:{"value":7}},[_vm._v(" 滑动&摇一摇 ")]):_vm._e(),([4, 6, 10].includes(+_vm.positionId) && +_vm.platInfo.accessType === 1)?_c('a-radio',{attrs:{"value":8}},[_vm._v(" 蒙层滑动 ")]):_vm._e()],1)],1):_vm._e(),(
      _vm.formquery.hotspot === 'A' &&
        ((+_vm.platInfo.accessType === 1 &&
        (+_vm.positionId === 1 ||
        +_vm.positionId === 3 ||
        (+_vm.positionId === 10 && +_vm.formquery.adStyle === 1) ||
        +_vm.positionId === 6)) ||
        ((((+_vm.positionId === 1 || +_vm.positionId === 3) && +_vm.formquery.isAutoDrawing === 1) ||
        +_vm.positionId === 4 ||
        (+_vm.positionId === 10 && +_vm.formquery.adStyle === 2)) &&
        (+_vm.formquery.platId === 1 ||
        +_vm.formquery.platId === 2 ||
        +_vm.formquery.platId === 3 ||
        +_vm.formquery.platId === 4 ||
        +_vm.formquery.platId === 6 ||
        +_vm.formquery.platId === 10 ||
        +_vm.formquery.platId === 12 ||
        +_vm.formquery.platId === 14 ||
        +_vm.formquery.platId === 19)) ||
        (+_vm.positionId === 10 && +_vm.formquery.adStyle === 2 && +_vm.formquery.platId === 0) ||
        (+_vm.positionId === 5 && +_vm.formquery.platId === 0)) &&
        [4, 6, 10].includes(+_vm.positionId) &&
        +_vm.platInfo.accessType === 1 &&
        +_vm.formquery.hotspotType === 8
    )?_c('a-form-model-item',{attrs:{"label":"蒙层显示","prop":"displayMask"}},[_c('a-radio-group',{attrs:{"name":"radioGroup"},model:{value:(_vm.formquery.displayMask),callback:function ($$v) {_vm.$set(_vm.formquery, "displayMask", $$v)},expression:"formquery.displayMask"}},[_c('a-radio',{attrs:{"value":1}},[_vm._v(" 开启 ")]),_c('a-radio',{attrs:{"value":0}},[_vm._v(" 关闭 ")])],1)],1):_vm._e(),(
      _vm.formquery.hotspot === 'A' &&
        (_vm.formquery.hotspotType === 2 || _vm.formquery.hotspotType === 6 || _vm.formquery.hotspotType === 7) &&
        (_vm.role === 'SUPER_ADMIN' || +_vm.sensitivity === 1) &&
        (+_vm.platInfo.accessType === 1 ||
        ((+_vm.positionId === 1 || +_vm.positionId === 3) && +_vm.formquery.isAutoDrawing === 1) ||
        (+_vm.positionId === 10 && +_vm.formquery.adStyle === 1) ||
        +_vm.positionId === 6)
    )?_c('a-form-model-item',{attrs:{"prop":"sensitivity"}},[_c('m-tips',{attrs:{"slot":"label","content":`灵敏度常规取值范围0-100，不在此范围的数值，摇一摇按照完全合规执行。`,"title":'灵敏度',"width":200},slot:"label"}),_c('a-input-number',{staticStyle:{"width":"200px"},attrs:{"placeholder":"请输入灵敏度","min":0,"max":100},model:{value:(_vm.formquery.sensitivity),callback:function ($$v) {_vm.$set(_vm.formquery, "sensitivity", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formquery.sensitivity"}})],1):_vm._e(),(+_vm.positionId === 1 && _vm.formquery.hotspot === 'A')?_c('a-form-model-item',{attrs:{"label":"交互样式大小","prop":"scaleType"}},[_c('a-radio-group',{attrs:{"name":"radioGroup"},model:{value:(_vm.formquery.scaleType),callback:function ($$v) {_vm.$set(_vm.formquery, "scaleType", $$v)},expression:"formquery.scaleType"}},[_c('a-radio',{attrs:{"value":0}},[_vm._v(" 默认")]),_c('a-radio',{attrs:{"value":1}},[_vm._v(" 大 ")]),_c('a-radio',{attrs:{"value":2}},[_vm._v(" 超大 ")])],1)],1):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }