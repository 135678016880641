<template>
  <div class="aggregate-detail-container">
    <div class="detail_tabs_wrapper" v-if="testList.length > 1">
      <div class="detail_tabs">
        <a-tabs style="max-width: 800px" v-model="query.testId" @change="selectTab">
          <template v-for="item in testList">
            <a-tab-pane v-if="item.sts === 'A'" :key="item.testId">
              <div slot="tab">
                {{ item.groupName }}&nbsp;&nbsp;<span v-if="item.type === 0">{{ item.proportion }}%</span>
                <span v-if="item.type === 1">{{ getProportion(item) + '%' }}</span>
              </div>
            </a-tab-pane>
          </template>
        </a-tabs>
      </div>
      <div class="detail_tabs_setting_btn">
        <a-dropdown type="primary" :trigger="['click']">
          <a-menu slot="overlay">
            <a-menu-item key="1" @click="handleClickEditAbTest"> 编辑</a-menu-item>
            <a-menu-item key="2" @click="toAbtestData"> A/B测试数据</a-menu-item>
            <a-menu-item key="3" @click="closeAbtestVisible = true"> 结束测试</a-menu-item>
          </a-menu>
          <a-button style="margin-left: 8px"> 设置 <a-icon type="down" /> </a-button>
        </a-dropdown>
      </div>
    </div>
    <AggregateTable
      v-bind="$attrs"
      :abtestNum="testList.length"
      :testList="testList"
      :query="query"
      :positionId="positionId"
      :concurrentNum="concurrentNum"
      @changePlatId="changePlatId"
    />
    <AbtestModal
      v-on="$listeners"
      v-bind="$attrs"
      v-if="addAbtestVisible"
      :visible="addAbtestVisible"
      @modalCancel="addAbtestVisible = false"
      :abtestInfo="groupInfo"
      :isAdd="false"
      :query="query"
    />

    <CloseAbtestModal
      v-on="$listeners"
      v-bind="$attrs"
      v-if="closeAbtestVisible"
      :visible="closeAbtestVisible"
      @modalCancel="closeAbtestVisible = false"
      :groupInfo="groupInfo"
      :query="query"
    />
  </div>
</template>

<script>
import AbtestModal from '../components/abtestModal'
import CloseAbtestModal from '../components/closeAbtestModal'
import AggregateTable from './aggregateTable'
export default {
  name: 'AggregateDetail',
  components: { AggregateTable, AbtestModal, CloseAbtestModal },
  props: {
    positionId: {
      default: 0,
      type: [Number, String]
    },
    // 当前选中的流量分组信息
    groupInfo: {
      type: Object,
      default: () => ({
        abTests: [],
        concurrentNum: 3,
        id: '',
        impressFreqDay: 0,
        impressFreqHour: 0,
        impressInterval: 0,
        name: '',
        priority: 0,
        segmentRule: [],
        sts: 'S',
        testSts: 'S'
      })
    },
    // 已完成的搜索条件（缺testId）
    searchQuery: {
      default: () => ({
        dateEnd: '',
        dateStart: '',
        appId: '',
        placeId: '',
        groupId: '',
        testId: ''
      }),
      type: Object
    }
  },
  data () {
    return {
      query: {
        dateEnd: '',
        dateStart: '',
        appId: '',
        placeId: '',
        groupId: '',
        testId: ''
      },
      addAbtestVisible: false,
      closeAbtestVisible: false
    }
  },
  computed: {
    // 当前流量分组下的ab测试分组列表
    testList () {
      return this.groupInfo.abTests
    },
    // 当前测试分组的并列请求数
    concurrentNum () {
      return this.testList.filter((item) => item.testId === this.query.testId)[0]
        ? this.testList.filter((item) => item.testId === this.query.testId)[0].concurrentNum
        : 0
    }
  },
  watch: {
    groupInfo: {
      handler (val, oldVal) {
        const oldTestId = this.query.testId
        // 即流量分组改变
        this.query = JSON.parse(JSON.stringify(this.searchQuery))
        // 防止出现切换数据的testId一直是default，无法监听
        this.query.testId = ''
        // 默认test分组
        if (val.abTests.length === 1) {
          // 当testList只有一项默认值，即default
          this.query.testId = val.abTests[0].testId // defalult
        } else {
          // 当testList存在时
          // 默认选中非default分组
          const sameId = val.abTests.find((item) => item.testId === oldTestId)
          if (sameId && sameId.testId !== 'default') {
            this.query.testId = oldTestId
          } else {
            this.query.testId = val.abTests.filter((item) => item.id !== 'default' && item.sts !== 'S')[0].testId
          }
        }
      },
      immediate: true
    },
    // 监听时间变化,由于父组件传过来的query中不存在testId,先保存一下testId
    'searchQuery.dateEnd': {
      handler () {
        const testId = this.query.testId
        this.query = JSON.parse(JSON.stringify(this.searchQuery))
        this.query.testId = testId
      },
      deep: true
    },
    'searchQuery.dateStart': {
      handler () {
        const testId = this.query.testId
        this.query = JSON.parse(JSON.stringify(this.searchQuery))
        this.query.testId = testId
      }
    }
  },
  methods: {
    // 跳转至ab测试数据页
    toAbtestData () {
      this.$router.push({
        name: 'abtestdata',
        query: {
          appId: this.query.appId,
          placeId: this.query.placeId,
          groupId: this.query.groupId
        }
      })
    },
    handleClickEditAbTest () {
      this.addAbtestVisible = true
    },
    selectTab () {
    },
    getProportion (item) {
      if (item.sts === 'A') {
        let proportion = 0
        item.intervalList.forEach((child) => {
          const p = child[1] - child[0] + 1
          proportion += p
        })
        return proportion
      } else {
        return 0
      }
    },
    changePlatId (data) {
      this.$emit('changePlat', data)
    }
  }
}
</script>
<style lang="less" scoped>
.aggregate-detail-container {
  padding: 0px 0px;
  .detail_tabs_wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .detail_tabs {
      margin: 0px 0px;
      padding: 0px 0;
    }
    .detail_tabs_setting_btn {
      button {
        margin: 0 10px;
      }
    }
  }
}
::v-deep.detail_tabs {
  .ant-tabs.ant-tabs-top.ant-tabs-line {
  }
  .ant-tabs-bar.ant-tabs-top-bar {
    margin: 0px;
  }
}
</style>
