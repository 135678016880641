<template>
  <div class="auto-equipment-chart-container">
    <a-spin :spinning="spinning">
      <div class="chart">
        <div v-show="hasData" class="chart-box">
          <Chart :option="option" />
        </div>
        <div class="empty" v-show="!hasData"></div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import option from './option'
import { mapState } from 'vuex'
import Chart from '@/components/common/Chart'
export default {
  name: 'AutoChart',
  components: { Chart },
  data () {
    return {
      spinning: false,
      data: [],
      lable: [],
      indicatorDataList: [],
      option: {
        tooltip: {
        },
        xAxis: {
          data: []
        },
        series: [
          {
            areaStyle: {
              color: undefined
            },
            data: []
          }
        ]
      },
      hasData: true
    }
  },
  props: {
    query: {
      default: () => ({}),
      type: Object
    },
    appList: {
      default: () => [],
      type: Array
    },
    placeList: {
      default: () => [],
      type: Array
    },
    chartResp: {
      default: () => ({}),
      type: Object
    }
  },
  watch: {
    query: {
      handler (val, oldval) {
        this.spinning = true
      },
      immediate: true,
      deep: true
    },
    chartResp: {
      handler (val) {
        const resp = JSON.parse(JSON.stringify(val))
        const { lable = [], values = [] } = resp.data || {}
        this.lable = lable
        this.data = values
        this.getChart()
        this.spinning = false
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapState({
      isMock: (state) => state.user.isMock
    })
  },
  created () {
  },
  mounted () {},
  methods: {
    getChart () {
      this.option = JSON.parse(JSON.stringify(option))
      // x轴刻度
      this.option.xAxis.data = this.lable
      this.option.series[0].areaStyle.color = {
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0, // 从左到右，但不能从上到下，需要借助辅助系列
        y2: 1,
        colorStops: [
          {
            offset: 0,
            color: '#B769F7' // 0% 处的颜色
          },
          {
            offset: 1,
            color: '#fff' // 100% 处的颜色
          }
        ],
        globalCoord: false // 缺省为 false
      }
      // 线数据
      this.option.series[0].data = this.data
      this.option.tooltip.formatter = (params) => {
        let str = ''
        params.forEach((item, index) => {
          str += '自动调整广告源个数: ' + item.value + '<br>'
        })
        return params[0].axisValue + '时<br>' + str
      }
    }
  }
}
</script>
<style lang="less" scoped>
.auto-equipment-chart-container {
  padding-top: 10px;
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
  .ant-spin-nested-loading {
    flex-grow: 1;

    .ant-spin-container {
      height: 100%;

      .chart {
        height: 280px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10px;
        .chart-box {
          width: 100%;
          height: 100%;
        }
        .empty {
          width: 100%;
          height: 100%;
          background-image: url('/images/data-empty.png');
          background-size: 60%;
          background-repeat: no-repeat;
          background-position: center;
        }
      }
    }
  }
}
</style>
