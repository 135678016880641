/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-01-19 17:13:36
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-03-26 18:25:06
 * @FilePath: /mediatom-web/src/data/dataList.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

// 部署平台列表
export const partnerIdList = [
  { name: 'Mediatom', id: '1' },
  { name: 'cMedia', id: '2' },
  { name: 'MergeX', id: '3' }
]

// 插屏样式列表
export const drawTypeList = [
  {
    name: '竖屏',
    id: '0'
  },
  {
    name: '横屏',
    id: '1'
  },
  {
    name: '信封',
    id: '2'
  }
]

// 时区列表
export const timeZoneList = [
  {
    name: 'UTC-8 太平洋标准时间',
    value: '-08:00'
  },
  {
    name: 'UTC+0 格林威治时间',
    value: '+00:00'
  },
  {
    name: 'UTC+8 中国标准时间',
    value: '+08:00'
  }
]

// 素材类型
export const creativeTypeList = [
  {
    name: '图片',
    id: '0'
  },
  {
    name: '视频',
    id: '1'
  }
]
