<template>
  <a-modal
    v-model="secondModalVisible"
    :title="isGuidan? '自动运营向导-批量创建广告源' : '添加广告源向导-批量创建广告源'"
    @cancel="handleCancel"
    width="900px"
    :maskClosable="false"
  >
    <div class="footer" slot="footer">
      <div>
        <a-button type="link" @click="toDoc" v-if="showLink"> 如何找到参数? </a-button>
      </div>
      <div class="btn-list">
        <a-button :loading="isLoading" @click="handlePrev"> 上一步 </a-button>
        <a-button :loading="isLoading" key="submit" type="primary" @click="handleNext"> {{ isGuidan ? '下一步' : '完成' }} </a-button>
      </div>
    </div>
    <a-spin tip="正在创建中，请耐心等待..." :spinning="isLoading">
      <!-- 步骤条 -->
      <Step :current="2" v-if="isGuidan" />
      <div class="plat-icon-list-wrapper">
        <div class="plat-icon-list-box" ref="scrollBox">
          <div class="plat-icon-list">
            <div
              class="plat-icon-item"
              @click="() => {
                currentIndex = index
                handleScroll()
              }"
              :class="{ isactive: currentIndex === index, isfailed: item.isFailed }"
              :title="item.name"
              v-for="(item, index) in formquery.renderList"
              :key="item.id"
            >
              <img :src="item.icon" alt="" />
              <div class="name">{{ item.name }}</div>
              <div class="checked-box" v-if="currentIndex === index"></div>
            </div>
          </div>
        </div>
        <div class="left-btn btn"><a-icon @click="handleSkipPlat('prev')" type="left" /></div>
        <div class="right-btn btn"><a-icon @click="handleSkipPlat('next')" type="right" /></div>
      </div>
      <a-form-model class="form-wrapper" ref="ruleForm" :model="formquery">
        <div v-if="formquery.renderList.length" class="plat-item-form" :key="'' + currentIndex">
          <!-- 头部 -->
          <div class="head">
            <div class="left">
              <a-popover v-if="currentPlatInfo.isAlliance" placement="top" trigger="hover">
                <a-icon
                  style="cursor: pointer; font-size: 14px; margin-right: 5px"
                  class="primary-text"
                  type="info-circle"
                />
                <div style="width: 150px" slot="content">
                  开启自动创建广告源功能，{{ title }}会自动在联盟后台创建广告位，您无需再登录联盟后台创建。
                </div>
              </a-popover>
              <span
              >当前为<span class="primary-text">{{ currentPlatInfo.isAuto ? '自动创建' : '模版创建' }}</span></span
              >
              &nbsp;&nbsp;
              <span>{{ currentPlatInfo.isAuto ? '请填写以下参数：' : '请配置模版：' }}</span>
              <span
                :style="{ color: currentPlatInfo.isSuccess === 0 ? 'red' : '#000' }"
                class="result"
                v-if="
                  currentPlatInfo.isSuccess !== undefined && currentPlatInfo.isSuccess !== -1 && currentPlatInfo.isAuto
                "
              >
                <a-icon type="switcher" />{{
                  currentPlatInfo.isSuccess === 0
                    ? '创建失败，请检查参数'
                    : currentPlatInfo.isSuccess === -1
                      ? ''
                      : `创建成功${currentPlatInfo.isSuccess}条`
                }}
              </span>
            </div>
            <div class="right" v-if="currentPlatInfo.isAlliance">
              <a-button
                type="link"
                @click="handleChangeIsAuto"
              >切换{{ currentPlatInfo.isAuto ? '模版' : '自动' }}创建</a-button
              >
            </div>
          </div>
          <div class="form-list">
            <!-- platAppId -->
            <div class="form-item-wrapper plat-app-id">
              <a-form-model-item
                :label="`APPID`"
                :prop="`renderList[${currentIndex}].platAppId`"
                :labelCol="{ span: 6, offset: 0 }"
                class="form-item"
              >
                <a-input :value="currentPlatInfo.platAppId" disabled></a-input>
              </a-form-model-item>
            </div>
            <!-- accountId -->
            <div
              class="form-item-wrapper"
              v-if="
                (+currentPlatInfo.id === 1 || +currentPlatInfo.id === 3 || +currentPlatInfo.id === 2) &&
                  currentPlatInfo.isAuto
              "
            >
              <a-form-model-item
                :label="getUserIdLabel(currentPlatInfo.id)"
                :prop="`renderList[${currentIndex}].accountId`"
                :labelCol="{ span: 6, offset: 0 }"
                class="form-item"
                :rules="[{ required: true, message: `请输入${getUserIdLabel(currentPlatInfo.id)}`, trigger: 'blur' }]"
              >
                <a-input
                  :placeholder="`请输入${getUserIdLabel(currentPlatInfo.id)}`"
                  v-model.trim="formquery.renderList[currentIndex].accountId"
                ></a-input>
              </a-form-model-item>
            </div>
            <!-- accessKey -->
            <div
              class="form-item-wrapper"
              v-if="
                (+currentPlatInfo.id === 1 || +currentPlatInfo.id === 3 || +currentPlatInfo.id === 6) &&
                  currentPlatInfo.isAuto
              "
            >
              <a-form-model-item
                :label="getAccessKeyLabel(currentPlatInfo.id)"
                :prop="`renderList[${currentIndex}].accessKey`"
                :labelCol="{ span: 6, offset: 0 }"
                class="form-item"
                :rules="[
                  { required: true, message: `请输入${getAccessKeyLabel(currentPlatInfo.id)}`, trigger: 'blur' }
                ]"
              >
                <a-input
                  :placeholder="`请输入${getAccessKeyLabel(currentPlatInfo.id)}`"
                  v-model.trim="formquery.renderList[currentIndex].accessKey"
                ></a-input>
              </a-form-model-item>
            </div>
            <!-- secretKey -->
            <div
              class="form-item-wrapper"
              v-if="
                (+currentPlatInfo.id === 1 || +currentPlatInfo.id === 3 || +currentPlatInfo.id === 2) &&
                  currentPlatInfo.isAuto
              "
            >
              <a-form-model-item
                :label="getSecretLabel(currentPlatInfo.id)"
                :prop="`renderList[${currentIndex}].secretKey`"
                :labelCol="{ span: 6, offset: 0 }"
                class="form-item"
                :rules="[{ required: true, message: `请输入${getSecretLabel(currentPlatInfo.id)}`, trigger: 'blur' }]"
              >
                <a-input
                  :placeholder="`请输入${getSecretLabel(currentPlatInfo.id)}`"
                  v-model.trim="formquery.renderList[currentIndex].secretKey"
                ></a-input>
              </a-form-model-item>
            </div>
          </div>
          <a-form-model-item v-if="+currentPlatInfo.id === 6" label="公钥" :labelCol="{ span: 3, offset: 0 }">
            <a-input disabled v-model.trim="formquery.renderList[currentIndex].account.signKey" style="width:550px">
              <span slot="addonAfter" class="copy" @click="handleCopy(formquery.renderList[currentIndex].account.signKey)">复制</span>
            </a-input>
          </a-form-model-item>
          <a-alert
            v-if="currentPlatInfo.isAuto"
            style="font-size: 12px; margin-left: 40px; border-radius: 5px"
            :message="`注意：使用${currentPlatInfo.name}自动创建代码位功能，需要在${currentPlatInfo.name}后台开通（应用代码位管理API）相关权限`"
            banner
          />
          <div class="config-source-box" v-if="!currentPlatInfo.isAuto">
            <a-button
              size="small"
              type="cust"
              style="margin-right: 10px"
              @click="handleAddSource()"
            >开始模版配置</a-button
            >
            <span><a-icon type="switcher" />已配置{{ currentPlatInfo.sourceCount }}条</span>
          </div>
        </div>
      </a-form-model>
    </a-spin>
    <GuidanceAddModal
      :visible="addSourceVisible"
      @modalCancel="addSourceVisible = false"
      :addinfo="addSourceQuery"
      @addSourceSuccess="setSourceCount"
    />
  </a-modal>
</template>

<script>
import Step from './Step.vue'
import { platAccountForAutoOperator, getSourceCount, postAutoCreatePlat } from '@/api/aggregateGuide'
import { getAccessKeyLabel, getSecretLabel, getUserIdLabel } from '@/utils/autoLabel'
import GuidanceAddModal from './GuidanceAddModal'
import { updatePlatForAutoOperator } from '@/api/advertisement'
import { mapState } from 'vuex'
import { copy } from '@/utils/string'
export default {
  name: 'SecondModal',
  components: { Step, GuidanceAddModal },
  data () {
    return {
      // 表单
      formquery: {
        renderList: []
      },
      // 全部默认账户信息
      allPlatInfo: [],
      // 当前选中的平台索引
      currentIndex: 0,
      // 是否在加载
      isLoading: false,
      addSourceQuery: {},
      addSourceVisible: false,
      linkList: [
        {
          id: 1,
          link: '232'
        },
        {
          id: 2,
          link: '233'
        },
        {
          id: 3,
          link: '234'
        },
        {
          id: 6,
          link: '235'
        }
      ]
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    platIds: {
      default: () => ['1', '2', '3', '6', '5'],
      type: Array
    },
    query: {
      default: () => ({}),
      type: Object
    },
    placeInfo: {
      default: () => ({}),
      type: Object
    },
    // 是否引导
    isGuidan: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    showLink () {
      return this.linkList.find((item) => +item.id === +this.currentPlatInfo?.id)
    },
    secondModalVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    },
    // 当前选中的平台信息
    currentPlatInfo () {
      return this.formquery.renderList[this.currentIndex]
    },
    ...mapState({
      // 平台名
      title: (state) => state.autoweb.title
    })
  },
  watch: {
    visible: {
      async handler (val) {
        if (val) {
          this.currentIndex = 0
          this.formquery.renderList = []
          // 获取全部平台的默认账号信息
          await this.getAllPlatInfo()
          // 将列表保存
          this.getRenderList()
          // 获取创建的广告源数量
          this.setSourceCount()
        }
      }
    }
  },
  methods: {
    getUserIdLabel,
    getAccessKeyLabel,
    getSecretLabel,
    handleCopy (id) {
      const result = copy(id)
      if (result === false) {
        this.$message.error('不支持复制')
      } else {
        this.$message.success('复制成功')
      }
    },
    toDoc () {
      const linkId = this.linkList.find((item) => +item.id === +this.currentPlatInfo.id)?.link
      window.open(`https://app.mediatom.cn/docs/#/?blogid=${linkId}`, '_blank')
    },
    handleSkipPlat (type) {
      if (type === 'next') {
        this.currentIndex = (this.currentIndex + 1) % this.formquery.renderList.length
      } else {
        this.currentIndex = this.currentIndex === 0 ? this.formquery.renderList.length - 1 : this.currentIndex - 1
      }
      this.handleScroll()
    },
    handleScroll () {
      const wrapper = this.$refs.scrollBox
      const iconList = document.getElementsByClassName('plat-icon-item')
      const icon = iconList[this.currentIndex]
      // 向左滚动距离 = (icon宽度 + margin间隔) * 当前索引 - 容器宽度 / 2 - (icon宽度 + margin间隔) * 0.5
      const left = (icon.clientWidth + 10) * (this.currentIndex + 1 / 2) - wrapper.getBoundingClientRect().width / 2
      wrapper.scrollTo({ left, behavior: 'smooth' })
    },
    handleAddSource () {
      this.addSourceQuery = {
        appId: this.query.appId,
        placeId: this.query.placeId,
        platId: this.currentPlatInfo.id,
        platAppId: this.currentPlatInfo.platAppId,
        positionId: this.placeInfo.position,
        platAccountId: this.currentPlatInfo.account.id
      }
      this.addSourceVisible = true
    },
    handleChangeIsAuto () {
      const isAuto = this.currentPlatInfo.isAuto
      this.formquery.renderList[this.currentIndex].isAuto = !isAuto
    },
    // 获取渲染列表
    getRenderList () {
      this.formquery.renderList = [...this.platIds].map((item) => {
        // 获取平台账户信息
        const platAccountInfo = this.allPlatInfo.find((i) => +i.platId === +item)
        // 账户信息
        const { account, platId } = platAccountInfo
        // 是否是四大联盟
        const isAlliance = [1, 2, 3, 6].includes(+platId)
        const obj = {
          // 账户信息
          account,
          // icon
          icon: platAccountInfo.icon,
          name: platAccountInfo.platName,
          id: platId,
          platAccountId: account.id,
          accountName: account.name,
          // 是否是四大联盟
          isAlliance,
          // 默认选择自动创建
          isAuto: isAlliance,
          // 应用id
          platAppId: account.platAppId,
          // 广告源数量
          sourceCount: 0,
          // 是否开启了自动创建广告源权限
          isAutoCreate: account.isAutoCreate || '0',
          // 平台类型 sdk api
          accessType: platAccountInfo.accessType,
          // 价格类型
          settleType: platAccountInfo.settleType,
          isFailed: false
        }
        this.$set(obj, 'accountId', account.accountId)
        this.$set(obj, 'accessKey', account.accessKey)
        this.$set(obj, 'secretKey', account.secretKey)
        return obj
      })
    },
    // 获取所有广告平台及绑定账号信息
    async getAllPlatInfo () {
      const { data = [] } = await platAccountForAutoOperator({
        appId: this.query.appId
      })
      this.allPlatInfo = data
    },
    // 设置配置广告源数量
    async setSourceCount () {
      const { data = [] } = await getSourceCount({
        placeId: this.query.placeId
      })
      data.forEach((item) => {
        const renderItem = this.formquery.renderList.find((child) => +child.id === +item.platId)
        if (renderItem) {
          renderItem.sourceCount = item.num
        }
      })
    },
    handleCancel () {
      this.secondModalVisible = false
    },
    // 代码验证表单
    custValidate () {
      const validList = []
      this.formquery.renderList.forEach((item) => {
        item.isFailed = false
        if (!item.isAuto || !item.isAlliance) {
          return
        }
        let isPass = true
        // accountId
        if ([1, 2, 3].includes(+item.id) && !item.accountId) {
          isPass = false
        }
        // accessKey
        if ([1, 6, 3].includes(+item.id) && !item.accessKey) {
          isPass = false
        }
        // secretKey
        if ([1, 2, 3].includes(+item.id) && !item.secretKey) {
          isPass = false
        }
        if (!isPass) {
          validList.push(item.id)
        }
      })
      return validList
    },
    // 批量处理需要自动创建平台的账户修改
    async handleBatchEditAccount (plats) {
      // 创建promise数组
      const promiseEdit = []
      // 批量发送修改请求
      plats.forEach((item) => {
        const premiseItem = updatePlatForAutoOperator({
          ...item.account,
          platId: item.id,
          accountId: item.accountId,
          accessKey: item.accessKey,
          secretKey: item.secretKey,
          platAppId: item.platAppId
        })
        promiseEdit.push(premiseItem)
      })
      // 处理批量异步
      const results = await Promise.all(promiseEdit)
      if (results.every((item, index) => item.code === 200 && item.msg === plats[index].account.name)) {
        // 全部编辑完成
        return []
      } else {
        // 有部分表单未完成编辑
        const list1 = results.map((res, index) => ({ msg: res.msg, id: plats[index].id, index }))
        const list2 = list1.filter((item) => item.msg !== plats[item.index].account.name)
        return list2.map((item) => item.id)
      }
    },
    /**
     * 处理批量保存
     * @param list 失败索引数组
     * @returns 是否结束执行
     */
    handleFailed (list, msg) {
      if (list.length > 0) {
        this.$message.error(msg || '请填写完整表单')
        // 若错误列表中不含当前平台，则跳至列表第一项的平台
        if (!list.includes(this.currentPlatInfo.id)) {
          this.currentIndex = this.formquery.renderList.findIndex((p) => +p.id === list[0])
        }
        // 将错误平台列表中的每一项加上错误标志
        list.forEach((item) => {
          this.formquery.renderList.find((p) => +p.id === +item).isFailed = true
        })
        // 触发表单验证
        this.$nextTick(() => {
          this.$refs.ruleForm.validate(() => {})
        })
        return true
      }
      return false
    },
    // 下一步按钮（提交）
    async handleNext () {
      // 手动验证自动创建表单
      const validList = this.custValidate()
      // 进行表单验证失败处理
      if (this.handleFailed(validList)) {
        return
      }
      this.isLoading = true
      // 获取需要
      const autoCreateList = this.formquery.renderList.filter((item) => item.isAuto)
      // 拿到失败平台ID列表
      const failedList = await this.handleBatchEditAccount(autoCreateList)
      // 进行失败处理
      if (this.handleFailed(failedList)) {
        this.isLoading = false
        return
      }
      const modalList = autoCreateList.map((item) => {
        return {
          platId: item.id,
          platAccountId: item.account.id,
          platAppId: item.platAppId
        }
      })
      // 上报需要自动创建的平台
      if (modalList.length) {
        const query = {
          appId: this.query.appId,
          placeId: this.query.placeId,
          position: this.placeInfo.position,
          platAccountVoList: modalList
        }
        const { data = [] } = await postAutoCreatePlat(query)
        this.formquery.renderList.forEach((item) => {
          const plat = data.find((d) => +d.platId === +item.id)
          if (!plat) {
            return
          }
          // 自动创建是否成功
          // code 0 表示失败，1 表示成功
          // 添加isSuccess字段，用于判断是否成功
          // 0 表示失败，大于0 表示成功，-1 表示未执行
          this.$set(item, 'isSuccess', -1)
          item.isSuccess = +plat.num
          // 失败且广告源数量小于3
          if (+plat.code === -1 && item.sourceCount < 3) {
            item.isSuccess = 0
          }
          // 失败但广告源数量大于3
          if (+plat.code === -1 && item.sourceCount > 3) {
            item.isSuccess = item.sourceCount
          }
        })
      }
      if (!this.formquery.renderList.some((item) => +item.isSuccess === 0 && item.isAuto)) {
        // 创建成功
        this.$message.success('操作成功！')
        this.$emit('modalNext2')
      } else {
        const platIdList = this.formquery.renderList
          .filter((item) => item.isSuccess === 0 && item.isAuto)
          .map((p) => p.id)
        this.handleFailed(platIdList, '部分平台创建失败，请检查参数')
      }
      this.isLoading = false
    },
    // 上一步
    handlePrev () {
      this.$emit('goPrev', this.platIds)
    }
  }
}
</script>

<style lang="less" scoped>
.plat-icon-list-wrapper {
  @iconHeight: 110px;
  @btnWidth: 30px;
  width: 100%;
  user-select: none;
  // border: 1px solid #ccc;
  // border-radius: 10px;
  height: @iconHeight;
  padding: 5px calc(@btnWidth + 5px);
  position: relative;
  .plat-icon-list-box {
    height: 100%;
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    .plat-icon-list {
      height: 100%;
      width: 100%;
      display: flex;
      padding: 5px;
      position: relative;
      .plat-icon-item {
        min-width: 180px;
        border: 1px dashed #ccc;
        margin-right: 5px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        padding-left: 20px;
        padding-right: 10px;
        // background-color: #fafafa;
        opacity: 0.5;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        &.isactive {
          opacity: 1;
          border: 1px solid @primary-color;
        }
        &.isfailed {
          outline: 1px solid red;
        }
        img {
          max-width: 50px;
          border-radius: 3px;
          box-shadow: 2px 2px 5px 0 #ccc;
          margin-right: 10px;
        }
        div.name {
          flex-grow: 1;
          color: #000;
          font-weight: 500;
          font-size: 18px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .checked-box {
          @boxSize: 50px;
          width: @boxSize;
          height: @boxSize;
          position: absolute;
          background-color: @primary-color;
          z-index: 99;
          bottom: calc(-@boxSize / 2);
          right: calc(-@boxSize / 2);
          transform: rotate(45deg);
          &::after {
            @innerWidth: 16px;
            @innerHeight: 8px;
            content: '';
            position: absolute;
            width: @innerWidth;
            height: @innerHeight;
            box-sizing: border-box;
            color: #fff;
            border-bottom: 2.5px solid;
            border-left: 2.5px solid;
            transform: rotate(-90deg);
            top: 2.5 * @innerHeight;
            // left: @innerHeight / 2;
            border-radius: 0 0 0 3px;
          }
        }
      }
    }
  }
  .btn {
    height: 100%;
    width: @btnWidth;
    text-align: center;
    line-height: @iconHeight;
    background-color: rgba(256, 256, 256, 0.8);
    position: absolute;
    top: 0;
    color: #ccc;
    font-size: 20px;
    i {
      cursor: pointer;
      &:hover {
        color: #666;
      }
    }
  }
  .left-btn {
    left: 0;
    // border-radius: 10px 0 0 10px;
    // box-shadow: 3px 0 4px 0 #ccc;
  }
  .right-btn {
    right: 0;
    // border-radius: 0 10px 10px 0;
    // box-shadow: -3px 0 4px 0 #ccc;
  }
}
.form-wrapper {
  height: 260px;
  margin-top: 20px;
  // border: 1px solid #eee;
  // border-radius: 10px;
  padding: 10px 0px;
}
.plat-item-form {
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    .left {
      padding-left: 50px;
    }
    .right {
      padding-right: 25px;
    }
  }
  .form-list {
    width: 100%;
    // height: 140px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    .form-item-wrapper {
      width: 50%;
      .form-item {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        input {
          width: 260px;
        }
      }
    }
  }
  .copy{
    cursor: pointer;
  }
}
.config-source-box {
  padding: 0 50px;
}
.footer {
  display: flex;
  justify-content: space-between;
}
</style>
