/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2023-10-23 10:34:45
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-01-09 14:45:47
 * @FilePath: /mediatom-web/src/api/autoManagement.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'
// 获取自动化运营图表
export function getAutoChart (data) {
  return request({
    url: `/aggregate/autoOperatorChart`,
    method: 'post',
    data
  })
}
// 开启关闭自动运营
export function updateOperatorSts (data) {
  return request({
    url: `/aggregate/updateOperatorSts`,
    method: 'post',
    data
  })
}

// 自动运营日志
export function autoOperatorLogList (data) {
  return request({
    url: `/aggregate/autoOperatorLogList`,
    method: 'post',
    data
  })
}
