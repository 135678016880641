<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-02-26 12:17:28
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-07-25 18:46:36
 * @FilePath: /mediatom-web/src/components/sourceModal/PlatAPISourceForm/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div>
    <a-form-model-item v-if="+positionId !== 5" prop="autoPlayType" label="视频播放">
      <a-radio-group name="radioGroup" v-model="formquery.autoPlayType">
        <a-radio :value="0"> 自动播放 </a-radio>
        <a-radio :value="1"> 仅wifi下自动播放 </a-radio>
      </a-radio-group>
    </a-form-model-item>
    <!-- 喜从天降 -->
    <a-form-model-item v-if="showXicongtianjiang" prop="redPackageRain">
      <m-tips
        slot="label"
        :content="`为增强用户与广告的互动效果，Mediatom针对${title} SDK渲染的开屏、插屏广告场景，适配了喜从天降（红包雨）的创意互动效果；${title} SDK 版本2.7.0及以上支持该功能`"
        :title="'喜从天降'"
        :width="200"
      ></m-tips>
      <a-radio-group name="radioGroup" v-model="formquery.redPackageRain">
        <a-radio value="A"> 开启 </a-radio>
        <a-radio value="S"> 关闭 </a-radio>
      </a-radio-group>
    </a-form-model-item>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'PlatAPISourceForm',
  data () {
    return {
      formqueryTemp: {
        redPackageRain: 'S'
      }
    }
  },
  computed: {
    formquery: {
      get () {
        return this.query
      },
      set (val) {
        const formquery = {
          ...this.formqueryTemp,
          ...val
        }
        this.$emit('changeQuery', formquery)
      }
    },
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      title: (state) => state.autoweb.title,
      adminUserId: (state) => state.user.adminUserId,
      buttonSize: (state) => state.asyncPermission.buttonSize,
      currentLoginId: (state) => state.user.id
    }),
    isAdmin () {
      if (this.role === 'SUPER_ADMIN' || +this.adminUserId === 1) {
        return true
      }
      return false
    },
    // 是否展示喜从天降
    showXicongtianjiang () {
      /**
       * 展示条件：
       * (开屏 || 插屏 || 激励视频) && 广告平台为API或直客投放
       * or
       * 开屏广告样式下---穿山甲、优量汇、快手、百度 选择开启自渲染
       * or
       * 插屏广告样式下---穿山甲、优量汇、快手、百度、京准通 选择开启自渲染
       * 则显示 喜从天降
       * */
      const platId = this.platInfo.id
      const show =
        ((+this.positionId === 1 || +this.positionId === 3 || +this.positionId === 5) &&
          +this.platInfo.accessType === 1) ||
        (+this.positionId === 1 &&
          (platId === 1 || platId === 2 || platId === 3 || platId === 4 || platId === 6) &&
          +this.formquery.isAutoDrawing === 1) ||
        (+this.positionId === 3 &&
          (platId === 1 || platId === 2 || platId === 3 || platId === 4 || platId === 6 || platId === 5) &&
          +this.formquery.isAutoDrawing === 1)
      return show
    }
  },
  props: {
    query: {
      default: () => ({}),
      type: Object
    },
    positionId: {
      default: 0,
      type: Number
    },
    platInfo: {
      default: () => ({}),
      type: Object
    }
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
</style>
