export const csjadTypeList = [
    { name: '信息流', id: 1 },
    { name: 'draw信息流', id: 2 },
    { name: '贴片', id: 3 }
  ]

  export const ylhadTypeList = [
    { name: '信息流', id: 1 },
    { name: '详情页插入', id: 2 },
    { name: '视频贴片', id: 3 },
    { name: '沉浸式视频流', id: 4 }
  ]

  export const ksadTypeList = [
    { name: '信息流', id: 1 },
    { name: 'draw视频', id: 2 }
  ]

  export const bddTypeList = [
    { name: '信息流', id: 1 },
    { name: '视频贴片', id: 2 },
    { name: '竖板视频', id: 3 }

  ]

  export const otadStyleList = [
    { name: '模版渲染', id: 1 },
    { name: '自渲染', id: 2 }
  ]

  export const bdadStyleList = [
    { name: '智能优选-优选模板', id: 1 },
    { name: '智能优选-自渲染', id: 2 }
  ]
