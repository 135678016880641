import { render, staticRenderFns } from "./ManualOperationPage.vue?vue&type=template&id=0b6a0b39&scoped=true&"
import script from "./ManualOperationPage.vue?vue&type=script&lang=js&"
export * from "./ManualOperationPage.vue?vue&type=script&lang=js&"
import style0 from "./ManualOperationPage.vue?vue&type=style&index=0&id=0b6a0b39&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b6a0b39",
  null
  
)

export default component.exports